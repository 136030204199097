import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import { Alert } from '@mui/material';
import { toast } from 'react-toastify';

import BusyButton from '../../../../BusyButton';
import { useInviteUserMutation } from '../../api';
import { OrganizationInviteList } from '../../../../repository/models/Invite';
import ValidatedTextInput from '../../../../hal/forms/ValidatedTextInput';
import { resolveTemplateRequired } from '../../../../hal';

interface InviteDialogProps {
    opened: boolean;
    inviteList: OrganizationInviteList;
    onClose: () => void;
}

export const InviteDialog = ({ opened, inviteList, onClose }: InviteDialogProps) => {
    const [email, setEmail] = useState('');
    const [inviteUser, { isLoading, isError, error, isSuccess, reset }] = useInviteUserMutation();

    const handleClose = () => {
        onClose();
        setEmail('');
    };

    useEffect(() => {
        if (isSuccess && !isError) {
            setEmail('');
            toast.success('Invitation was sent', {
                toastId: 'invite-user',
                closeButton: false,
            });
            onClose();
        }
    }, [isSuccess, isError, onClose, setEmail]);

    const handleInvite = () => {
        inviteUser({ inviteList, email });
    };

    useEffect(() => {
        if (!opened) {
            reset();
        }
    }, [opened, reset]);

    const halProperty = resolveTemplateRequired(inviteList, 'invite').property('email');

    const { classes } = useStyles();

    return (
        <>
            <Dialog open={opened} onClose={handleClose}>
                <DialogTitle>
                    Invite user
                    <Box component="span" sx={{ position: 'absolute', top: 0, right: 0 }}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Alert severity="info">
                        During the closed beta, no invitation email will be sent. You can only invite people that
                        already have an account on ContentGrid Console.
                    </Alert>
                </DialogContent>

                <DialogContent className={classes.dialogContent}>
                    {isError && (
                        <Alert className={classes.error} severity="error">
                            {error?.message}
                        </Alert>
                    )}
                    <ValidatedTextInput
                        fullWidth
                        autoFocus
                        placeholder="example@gmail.com"
                        value={email}
                        handleOnChange={setEmail}
                        halProperty={halProperty}
                        displayName="Email"
                    />
                    {/* <TextInput fullWidth autoFocus placeholder='example@gmail.com' value={email} handleOnChange={(value: string) => setEmail(value)}/> */}
                </DialogContent>

                <DialogActions>
                    <BusyButton
                        busy={isLoading}
                        color="primary"
                        variant="contained"
                        onClick={handleInvite}
                        disabled={!email}
                    >
                        Invite
                    </BusyButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

const useStyles = makeStyles()((theme) => ({
    error: {
        marginBottom: theme.spacing(2),
    },
    dialogContent: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));
