import { Code } from '@mui/icons-material';
import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, styled } from '@mui/material';
import RelativeTime from '@yaireo/relative-time';

import { Project } from '../../repository/models/Project';
import { AppState } from '../../store';
import OASIcon from '../../ui/icons/OASIcon';
import { RequestStateHandler } from '../../ui/RequestStateHandler';
import { useGetChangesetQuery } from '../application/api';
import { selectCurrentProject } from '../project/projectSlice';
import { INSTANCES_ROOT, RELEASE_SHOW, RELEASE_SHOW_OPENAPI } from '../routes';
import AutoBreadcrumbs from '../routes/AutoBreadcrumbs';
import { useGetDownloadUrlForChangesetQuery, useGetReleasesForProjectQuery } from './api';
import ReleaseChanges from './components/ReleaseChanges';
import { Overview, OverviewItem, OverviewRow, OverviewSubItem } from '../../ui/Overview';
import { ApiVersion } from './components/ApiVersion';
import { selectCurrentOrganization } from '../account/accountSlice';
import { Organization } from '../../repository/models/Organization';

interface ReleaseDetailsPageProps {
    readonly project: Project | null;
    readonly org: Organization | null;
}

const relativeTime = new RelativeTime();

function ReleaseDetailsPage(props: ReleaseDetailsPageProps) {
    const { data: releases, isLoading, error } = useGetReleasesForProjectQuery(props.project ?? skipToken);
    const location = useLocation();
    const match = RELEASE_SHOW.match(location.pathname);
    const navigate = useNavigate();

    const release = releases?.filter((release) => release.label === match?.params.release)[0];

    const {
        data: changeset,
        isLoading: changesetIsLoading,
        error: changesetError,
    } = useGetChangesetQuery(release?._links.changeset.href ?? skipToken);
    const { data: downloadUrl } = useGetDownloadUrlForChangesetQuery(release?._links.changeset.href ?? skipToken);

    const baseProjection = changeset?.projections.base;

    if (!match) {
        return null;
    }

    const instanceRootRouteWithQuery =
        INSTANCES_ROOT.generate({
            org: match.params.org,
            project: match.params.project,
        }) +
        '?deploying=' +
        release?.label;

    return (
        <>
            <AutoBreadcrumbs>
                <>Release {match.params.release}</>
            </AutoBreadcrumbs>
            <RequestStateHandler isLoading={isLoading || changesetIsLoading} error={error ?? changesetError} />

            {release && changeset ? (
                <>
                    <Overview
                        title="Release information"
                        button={
                            <Box display="flex" flexWrap="nowrap">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate(instanceRootRouteWithQuery)}
                                >
                                    Deploy
                                </Button>
                            </Box>
                        }
                    >
                        <OverviewRow>
                            <OverviewItem grow={1} name="Label">
                                {release.label}
                            </OverviewItem>
                            <OverviewItem grow={3} name="Message">
                                {release.message ?? null}
                            </OverviewItem>
                        </OverviewRow>
                        <OverviewRow>
                            <OverviewItem grow={1} name="Created">
                                {relativeTime.from(new Date(release.created))}
                            </OverviewItem>
                            <OverviewItem grow={1} name="Author">
                                {<span>{release.author ?? '&mdash;'}</span>}
                            </OverviewItem>
                            <OverviewItem grow={2} name="REST API Version">
                                {!!baseProjection ? (
                                    <ApiVersion
                                        base={baseProjection?.version}
                                        current={changeset.projections.post.version}
                                    />
                                ) : null}
                            </OverviewItem>
                        </OverviewRow>
                        <OverviewRow>
                            <Box flexGrow={1}>
                                <OverviewSubItem label="OpenAPI specification">
                                    <AppLink
                                        icon={<OASIcon fontSize="small" />}
                                        text="Visit"
                                        url={RELEASE_SHOW.navigate(location.pathname, RELEASE_SHOW_OPENAPI)!}
                                    />
                                </OverviewSubItem>

                                <OverviewSubItem label="Generated source code">
                                    <AppLink
                                        icon={<Code fontSize="small" />}
                                        text="Download"
                                        url={downloadUrl ?? null}
                                    />
                                </OverviewSubItem>
                            </Box>
                        </OverviewRow>
                    </Overview>

                    <Overview
                        title="Changes in this release"
                        button={
                            'parent' in release._links ? (
                                <Button
                                    component={Link}
                                    to={RELEASE_SHOW.generate({
                                        org: props.org?.name!,
                                        project: props.project?.name!,
                                        release:
                                            releases.find(
                                                (item) => item._links.self.href === release._links.parent?.href,
                                            )?.label ?? release.label,
                                    })}
                                    color="primary"
                                    variant="outlined"
                                >
                                    Previous release
                                </Button>
                            ) : null
                        }
                    >
                        <ReleaseChanges release={release} isTable />
                    </Overview>
                </>
            ) : null}
        </>
    );
}

const AppLink = ({ url, icon, text }: { url: string | null; icon: React.ReactNode; text: string }) => {
    if (url === null) {
        return <>&mdash;</>;
    }

    return (
        <>
            {icon}
            <StyledLink rel="noopener noreferrer" target="_blank" href={url}>
                {text}
            </StyledLink>
        </>
    );
};

const StyledLink = styled('a')(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    width: 'max-content',
    paddingRight: theme.spacing(4),
    '&:hover': {
        color: theme.palette.primary.dark,
        textDecoration: 'underline',
    },
}));

export default connect((state: AppState) => ({
    org: selectCurrentOrganization(state),
    project: selectCurrentProject(state),
}))(ReleaseDetailsPage);
