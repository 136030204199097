import { Button, Theme } from '@mui/material';
import React, { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import { NavLink, useNavigate } from 'react-router-dom';
import BusyButton from '../../../BusyButton';

interface EventHandlerFormFooterProps {
    isCreating?: boolean;
    parentUrl: string;
    disabledSave: boolean;
    loading?: boolean;
    onSave: () => void;
    onRemove?: () => void;
}

export const EventHandlerFormFooter = ({
    isCreating,
    parentUrl,
    disabledSave,
    loading,
    onSave,
    onRemove,
}: EventHandlerFormFooterProps) => {
    const { classes } = useStyles();
    const navigate = useNavigate();

    const handleSaveClick = useCallback(() => {
        navigate(parentUrl);
        if (!disabledSave) {
            onSave();
        }
    }, [navigate, onSave, parentUrl, disabledSave]);

    const handleRemove = () => {
        if (!!onRemove) {
            onRemove();
        }
    };

    return (
        <div className={classes.footer}>
            <Button color="secondary" component={NavLink} to={parentUrl}>
                Cancel
            </Button>

            {!isCreating && (
                <Button variant="contained" color="danger" onClick={handleRemove}>
                    Delete
                </Button>
            )}

            <BusyButton
                busy={!!loading}
                variant="contained"
                color="primary"
                disabled={disabledSave}
                onClick={handleSaveClick}
            >
                Save
            </BusyButton>
        </div>
    );
};

const useStyles = makeStyles()((theme: Theme) =>
({
    footer: {
        display: 'flex',
        justifyContent: 'end',
        padding: theme.spacing(1),
        gap: theme.spacing(2),
    },
}),
);
