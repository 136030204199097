import React, { ReactNode } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { withHalProperty } from '../hal';

interface CheckboxIconLabelProps {
    name?: string;
    label?: string;
    checked: boolean;
    children: ReactNode;
    disabled?: boolean;
    onChange: (value: boolean) => void;
}

export const CheckboxIconLabel = ({ name, label, checked, children, disabled, onChange }: CheckboxIconLabelProps) => {
    const { classes } = useStyles();
    return (
        <FormControlLabel
            disabled={disabled}
            control={
                <Checkbox
                    disabled={disabled}
                    name={name}
                    checked={checked}
                    color="primary"
                    onChange={(e) => onChange(e.target.checked)}
                />
            }
            label={
                <Box className={classes.controlLabel}>
                    {children}
                    {label && (
                        <Typography component="span" noWrap>
                            {label}
                        </Typography>
                    )}
                </Box>
            }
        />
    );
};

export const HalCheckboxIconLabel = withHalProperty(CheckboxIconLabel);

const useStyles = makeStyles()((theme) =>
    ({
        controlLabel: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: theme.spacing(1),
        },
    }),
);
