import React from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Typography } from '@mui/material';
import { useDeletePolicyMutation } from './api';
import { PolicyDto } from '../../repository/models/PolicyDto';
import DeleteButton from '../../ui/button/DeleteButton';

type PolicyDeletionButtonProps = {
    policy: PolicyDto;
    onDelete: () => void;
};

export default function PolicyDeletionButton(props: PolicyDeletionButtonProps) {
    const [deletePolicy, { isLoading, error, reset }] = useDeletePolicyMutation();

    const _delete = async () => {
        await deletePolicy(props.policy).unwrap();
        props.onDelete();
    };

    return (
        <DeleteButton
            onDeleteConfirm={_delete}
            onDeleteCancel={reset}
            isLoading={isLoading}
            error={error}
            variant="outlined"
            icon={<DeleteForeverIcon />}
            dialogTitle={<>Delete Policy {props.policy.id}</>}
        >
            <Typography>Are you sure you want to delete this policy?</Typography>
            {props.policy.conditions.length > 0 ? (
                <Typography>{props.policy.conditions.length} conditions in this policy will be deleted too.</Typography>
            ) : null}
        </DeleteButton>
    );
}
