import { Box, Typography } from '@mui/material';
import React from 'react';

interface ActionProps {
    description: string;
    children: React.ReactNode
}

export default function Action(props: ActionProps) {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography>{props.description}</Typography>
            {props.children}
        </Box>
    );
}
