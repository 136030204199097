import { ListItemButton, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

import { Entity } from '../repository/models/Entity';
import { StyledNavLink } from './styles/StyledNavLink';

type EntityListItemProps = {
    entity: Entity;
    selected: boolean;
};
export const EntityListItem = ({ entity, selected }: EntityListItemProps): React.ReactElement => {
    const { classes } = useStyles();

    return (
        <ListItemButton
            component={StyledNavLink}
            selected={selected}
            key={entity.name}
            to={'?entity=' + entity.name}
            className={`${classes.linkClasses} ${selected ? classes.selected : ''}`}
        >
            {entity.name}
        </ListItemButton>
    );
};

const useStyles = makeStyles()((theme: Theme) =>
    ({
        linkClasses: {
            border: '1px solid',
            borderRight: 0,
            borderColor: theme.palette.divider,
            borderRadius: theme.shape.borderRadius,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            marginBottom: theme.spacing(1),
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'block',
            '&:last-child': {
                marginBottom: theme.spacing(2),
            },
            '&.is-selected': {
                backgroundColor: theme.palette.primary.light,
            },
        },
        selected: {
            backgroundColor: theme.palette.primary.light,
        },
    }),
);
