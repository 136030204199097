import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';

import IDataFetcher from '../../repository/IDataFetcher';
import { Blueprint } from '../../repository/models/Blueprint';
import { Entity } from '../../repository/models/Entity';
import { PolicyDto } from '../../repository/models/PolicyDto';
import { AppState } from '../../store';
import EntitySelector from '../../ui/EntitySelector';
import { selectCurrentBlueprint } from '../blueprint/blueprintSlice';
import { useGetEntityListQuery } from '../datamodel/api';
import { BLUEPRINT_PERMISSIONS, PERMISSIONS_EDIT, PERMISSIONS_NEW } from '../routes';
import AutoBreadcrumbs from '../routes/AutoBreadcrumbs';
import { useGetPoliciesForEntityQuery } from './api';
import { PolicyModelMinimized } from './PolicyModelMinimized';
import { EntityListItem } from '../../ui/EntityListItem';
import { PageTitle } from '../../ui/typography/PageTitle';
import { TextLoader } from '../../ui/TextLoader';
import { Alert, AlertTitle } from '@mui/material';
import { useVisitedTourLocation } from '../tour/hooks';
import { HighlightedComponent, permissionAddPolicyLocation } from '../tour';
import DataModelRequired from '../datamodel/DataModelRequired';
import ChangesReminder from '../blueprint/components/ChangesReminder';

type PermissionModelProps = {
    fetcher: IDataFetcher;
    currentBlueprint: Blueprint | null;
};

function PermissionModel(props: PermissionModelProps) {
    const { data: entityList } = useGetEntityListQuery(props.currentBlueprint ?? skipToken);
    const entities = entityList?._embedded?.entities;
    const [searchParams, setSearchParams] = useSearchParams();
    const routerPath = useLocation().pathname;
    const urlEntity = searchParams.get('entity');
    const selectedEntity = entities?.filter((e) => e.name === urlEntity)[0] ?? undefined;

    let queryParam =
        props.currentBlueprint && urlEntity ? { blueprint: props.currentBlueprint, entity: urlEntity } : skipToken;
    const { data: policies, isLoading: policiesLoading } = useGetPoliciesForEntityQuery(queryParam);

    const routeMatch = BLUEPRINT_PERMISSIONS.wildcard.match(routerPath);

    const newPolicyUrl = (entity: Entity) =>
        PERMISSIONS_NEW.generate({
            ...routeMatch!.params,
            entity: entity.name,
        });

    React.useEffect(() => {
        if (urlEntity === null && entities !== undefined && entities.length > 0) {
            let firstEntity = entities[0];
            if (firstEntity !== undefined) {
                setSearchParams({ entity: firstEntity.name }, { replace: true });
            }
        }
    }, [urlEntity, entities, setSearchParams]);

    const makeEditLink = (policy: PolicyDto) => {
        return PERMISSIONS_EDIT.generate({
            ...routeMatch!.params,
            entity: policy.entity!,
            policy: policy.id,
        });
    };

    useVisitedTourLocation(permissionAddPolicyLocation, () => (policies ?? []).length > 0);

    return (
        <>
            <AutoBreadcrumbs>
                <>Permissions</>
            </AutoBreadcrumbs>

            <ChangesReminder />

            {!!entities?.length ? (
                <EntitySelector
                    entities={entities ?? null}
                    selectedEntity={entities?.filter((e) => e.name === urlEntity)[0] ?? null}
                    EntityComponent={EntityListItem}
                >
                    {selectedEntity !== undefined ? (
                        <>
                            <PageTitle>
                                Permissions for <i>{selectedEntity.name}</i>
                            </PageTitle>

                            {policiesLoading || policies === undefined ? (
                                <Box
                                    mb={2}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                policies.map((policy, i) => (
                                    <PolicyModelMinimized
                                        key={urlEntity + ':' + i}
                                        to={makeEditLink(policy)}
                                        policy={policy}
                                    />
                                ))
                            )}

                            {policies?.length === 0 ? (
                                <Box mb={2}>
                                    <NoPolicies entity={selectedEntity} />
                                </Box>
                            ) : null}

                            <HighlightedComponent location={permissionAddPolicyLocation}>
                                <Button
                                    variant="contained"
                                    component={NavLink}
                                    to={newPolicyUrl(selectedEntity)}
                                    color="primary"
                                >
                                    Add Permission
                                </Button>
                            </HighlightedComponent>
                        </>
                    ) : null}
                </EntitySelector>
            ) : (
                <TextLoader isLoading={!entities}>
                    <DataModelRequired systemName="Permissions">
                        <Typography variant="body2">
                            ContentGrid permissions are based on entities and their attributes, so a data model is
                            necessary to configure permissions.
                        </Typography>
                    </DataModelRequired>
                </TextLoader>
            )}
        </>
    );
}

export default connect((state: AppState) => ({
    currentBlueprint: selectCurrentBlueprint(state),
}))(PermissionModel);

interface NoPoliciesProps {
    entity: Entity;
}

function NoPolicies(props: NoPoliciesProps) {
    return (
        <Alert severity="info">
            <AlertTitle>No policies defined</AlertTitle>
            <Typography variant="body2">
                ContentGrid permissions are default-deny. When no policies are defined, nobody has access to the{' '}
                <i>{props.entity.name}</i> entity.
            </Typography>
        </Alert>
    );
}
