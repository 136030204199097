import {
    Theme,
    MenuItem,
    ListSubheader,
    TextField,
    InputAdornment,
    Popper,
    PopperProps,
    Autocomplete,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import {
    PolicyConditionSuggestion,
    PolicyConditionSuggestionDescription,
    PolicyConditionSuggestionProperty,
    SuggestionOptionValue,
    SuggestionPropertyOption,
} from '../../repository/models/PolicyConditionSuggestion';
import JSONPointer from 'jsonpointer';
import { Error, WarningOutlined } from '@mui/icons-material';
import dataFetcher from '../../repository';
import { unsafeCastLink } from '../../hal';
import RemapPalette from '../../ui/theme/RemapPalette';

export type PolicyConditionEditProps = {
    properties: PolicyConditionSuggestion['properties'];
    description: PolicyConditionSuggestionDescription;
    onChange: (description: PolicyConditionSuggestionDescription) => void;
};

export default function PolicyConditionEdit(props: PolicyConditionEditProps) {
    const { classes } = useStyles();

    return (
        <>
            <Box className={classes.component}>
                {props.properties.lhs.map((prop) => (
                    <ControlledField
                        className={classes.input}
                        key={prop.ptr}
                        property={prop}
                        description={props.description}
                        onChange={props.onChange}
                    />
                ))}
            </Box>
            <Box className={classes.component}>
                <ControlledField
                    className={classes.input}
                    property={props.properties.operator}
                    description={props.description}
                    onChange={props.onChange}
                />
            </Box>
            <Box className={classes.component}>
                {props.properties.rhs.map((prop) => (
                    <ControlledField
                        className={classes.input}
                        key={prop.ptr}
                        property={prop}
                        description={props.description}
                        onChange={props.onChange}
                    />
                ))}
            </Box>
        </>
    );
}

const useStyles = makeStyles()((theme: Theme) => ({
    component: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    input: {
        '& .MuiInputBase-root > fieldset > legend[style="width: 0.01px;"]': {
            display: 'none',
        },
        '& .MuiInputBase-root': {
            borderRadius: 0,
        },
        '&:first-of-type .MuiInputBase-root': {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
        },
        '&:last-child .MuiInputBase-root': {
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
        },
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            width: 'initial',
        },
    },
}));

interface ControlledFieldProps {
    className?: string;
    property: PolicyConditionSuggestionProperty;
    description: PolicyConditionSuggestionDescription;
    onChange: (description: PolicyConditionSuggestionDescription) => void;
}
function ControlledField(props: ControlledFieldProps) {
    const pointer = JSONPointer.compile(props.property.ptr);
    const textPointer = JSONPointer.compile(props.property.text_ptr ?? props.property.ptr);
    const descriptionCopy = JSON.parse(JSON.stringify(props.description));

    if (props.property.type !== 'number' && props.property.options) {
        return (
            <ControlledOptionsInput
                className={props.className}
                error={props.property.has_error}
                warning={props.property.has_warning}
                type={props.property.type}
                hint={props.property.hint}
                value={pointer.get(props.description)}
                textValue={textPointer.get(props.description)}
                options={props.property.options}
                onChange={(value) => {
                    pointer.set(descriptionCopy, value);
                    props.onChange(descriptionCopy);
                }}
            />
        );
    }
    switch (props.property.type) {
        case 'text':
        case 'number':
            return (
                <ControlledTextInput
                    className={props.className}
                    error={props.property.has_error}
                    warning={props.property.has_warning}
                    type={props.property.type}
                    hint={props.property.hint}
                    value={textPointer.get(props.description)}
                    onChange={(value) => {
                        textPointer.set(descriptionCopy, value);
                        props.onChange(descriptionCopy);
                    }}
                />
            );
        default:
            return pointer.get(props.description);
    }
}

interface ControlledTextInputProps {
    className?: string;
    type: 'number' | 'text';
    error?: boolean;
    warning?: boolean;
    hint?: string;
    value: string;
    onChange: (value: string) => void;
}

function ControlledTextInput(props: ControlledTextInputProps) {
    const [currentValue, setCurrentValue] = useState<string | null>(null);
    useEffect(() => setCurrentValue(props.value), [props.value]);
    return (
        <TextField
            className={props.className}
            variant="outlined"
            margin="dense"
            error={props.error}
            helperText={props.hint ?? <span>&nbsp;</span>}
            slotProps={{
                input: {
                    startAdornment: props.error ? (
                        <InputAdornment position="start">
                            <Error color="error" />
                        </InputAdornment>
                    ) : (
                        props.warning && (
                            <InputAdornment position="start">
                                <RemapPalette from="warning" to="error">
                                    <WarningOutlined color="error" fontSize="small" />
                                </RemapPalette>
                            </InputAdornment>
                        )
                    ),
                },
            }}
            type={props.type}
            value={currentValue ?? ''}
            onBlur={() => (currentValue !== null ? props.onChange(currentValue) : void 0)}
            onChange={(ev) => setCurrentValue(ev.target.value)}
        />
    );
}

interface ControlledSelectProps {
    className?: string;
    options: readonly SuggestionPropertyOption[];
    error?: boolean;
    warning?: boolean;
    hint?: string;
    value: SuggestionOptionValue;
    onChange: (value: SuggestionOptionValue) => void;
}

function ControlledSelect(props: ControlledSelectProps) {
    const optionGroups = props.options
        .map((option) => option.group)
        .filter((group, i, list) => list.indexOf(group) === i);
    return (
        <TextField
            select
            className={props.className}
            variant="outlined"
            margin="dense"
            error={props.error}
            helperText={props.hint ?? <span>&nbsp;</span>}
            slotProps={{
                select: {
                    startAdornment: props.error ? (
                        <InputAdornment position="start">
                            <Error color="error" fontSize="small" />
                        </InputAdornment>
                    ) : (
                        props.warning && (
                            <InputAdornment position="start">
                                <RemapPalette from="warning" to="error">
                                    <WarningOutlined color="error" fontSize="small" />
                                </RemapPalette>
                            </InputAdornment>
                        )
                    ),
                    renderValue: (value) => {
                        const currentOption = props.options.find((opt) => value === JSON.stringify(opt.value));
                        if (currentOption) {
                            return <SelectOptionText option={currentOption} asSelectedValue />;
                        } else {
                            return null;
                        }
                    },
                },
            }}
            // Make sure that value can never be undefined, else we get a problem with an uncontrolled component
            value={JSON.stringify(props.value) ?? ''}
            onChange={(ev) => props.onChange(JSON.parse(ev.target.value as any))}
        >
            {optionGroups.flatMap((optGroup) =>
                ControlledSelectOptions({
                    label: optGroup,
                    options: props.options.filter((o) => o.group === optGroup),
                }),
            )}
        </TextField>
    );
}

interface ControlledSelectOptionsProps {
    label: string | undefined;
    options: readonly SuggestionPropertyOption[];
}

// Note: this is not a real react component, but a function that returns an array of react components
// We need to do it this way because the MUI <Select /> component *requires* that <MenuItem /> is a direct child
function ControlledSelectOptions({ label, options }: ControlledSelectOptionsProps): readonly any[] {
    if (label === undefined) {
        return options.map((option) => (
            <MenuItem
                key={JSON.stringify(option)}
                value={JSON.stringify(option.value)}
                disabled={option.disabled ?? false}
                title={option.hint}
            >
                <SelectOptionText option={option} />
            </MenuItem>
        ));
    } else {
        return [<ListSubheader key={'h:' + label}>{label}</ListSubheader>].concat(
            ControlledSelectOptions({ label: undefined, options: options }),
        );
    }
}

const useSelectOptionTextStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    prompt: {
        marginRight: 'auto',
        marginLeft: theme.spacing(1),
    },
    hint: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary,
        fontSize: '.85rem',
    },
}));

interface SelectOptionTextProps {
    option: SuggestionPropertyOption;
    asSelectedValue?: boolean;
}

function SelectOptionText({ option, asSelectedValue = false, ...props }: SelectOptionTextProps) {
    const { classes } = useSelectOptionTextStyles();
    if (asSelectedValue) {
        return <span>{option.prompt}</span>;
    }
    return (
        <div className={classes.root} {...props}>
            <span className={classes.prompt}>{option.prompt}</span>
            <span className={classes.hint}>{option.hint}</span>
        </div>
    );
}

interface ControlledOptionsInputProps {
    type: 'text' | 'select';
    className?: string;
    options: Exclude<PolicyConditionSuggestionProperty['options'], undefined>;
    error?: boolean;
    warning?: boolean;
    hint?: string;
    value: SuggestionOptionValue;
    textValue: string;
    onChange: (value: SuggestionOptionValue | string) => void;
}

function ControlledOptionsInput({ options, ...props }: ControlledOptionsInputProps) {
    if ('remote' in options) {
        return <RemoteControlledOptionsInput remote={options.remote.href} {...props} />;
    } else if ('inline' in options) {
        return <InlineControlledOptionsInput options={options.inline} {...props} />;
    } else {
        return <>{props.textValue}</>;
    }
}

type RemoteControlledOptionsInputProps = Omit<ControlledOptionsInputProps, 'options'> & {
    remote: string;
};

function RemoteControlledOptionsInput({ remote, ...props }: RemoteControlledOptionsInputProps) {
    const [options, setOptions] = useState<SuggestionPropertyOption[] | null>(null);
    useEffect(() => {
        const abortController = new AbortController();
        dataFetcher
            .fetchJson<SuggestionPropertyOption[]>(unsafeCastLink(remote), {
                signal: abortController.signal,
            })
            .then(setOptions);
        return () => abortController.abort();
    }, [remote]);

    if (options === null) {
        if (props.type === 'text') {
            return (
                <ControlledTextInput
                    type="text"
                    className={props.className}
                    error={props.error}
                    warning={props.warning}
                    hint={props.hint}
                    value={props.textValue}
                    onChange={props.onChange}
                />
            );
        }
        return null;
    }

    return <InlineControlledOptionsInput options={options} {...props} />;
}

type InlineControlledOptionsInputProps = Omit<ControlledOptionsInputProps, 'options'> & {
    options: readonly SuggestionPropertyOption[];
};

function InlineControlledOptionsInput({ type, ...props }: InlineControlledOptionsInputProps) {
    switch (type) {
        case 'select':
            return <ControlledSelect {...props} />;
        case 'text':
            return <ControlledAutocomplete {...props} />;
    }
}

const filter = createFilterOptions<SuggestionPropertyOption>();
const syntheticOption = Symbol('syntheticOption');

type ControlledAutocompleteProps = Omit<InlineControlledOptionsInputProps, 'type'>;

function ControlledAutocomplete(props: ControlledAutocompleteProps) {
    // Unfortunately, this ref is needed because onChange() & onBlur() run right after
    // each other, before the component has had a chance to re-render.
    // We need to ensure that the current input value is not emitted in props.onChange(),
    // else, the current input value takes priority over the item selected in the popup menu
    const currentInputValue = useRef<string | null>(null);

    const hasCurrentOption = props.options.find((opt) => opt.prompt === props.textValue);

    const currentOption: SuggestionPropertyOption =
        hasCurrentOption ??
        ({
            prompt: props.textValue ?? '',
            value: props.value,
            disabled: false,
            hint: 'Current value',
            [syntheticOption]: true,
        } as SuggestionPropertyOption);

    const additionalOptions = [];
    if (!hasCurrentOption && currentOption.prompt !== '') {
        additionalOptions.push(currentOption);
    }

    return (
        <Autocomplete
            className={props.className}
            options={props.options.concat(additionalOptions)}
            groupBy={(option) => option.group ?? ''}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.prompt)}
            getOptionDisabled={(option) => option.disabled ?? false}
            isOptionEqualToValue={(option, currentValue) => option.prompt === currentValue.prompt}
            filterOptions={(options, state) => {
                const newOptions = filter(options, state).filter(
                    (opt: SuggestionPropertyOption) => !Object.hasOwn(opt, syntheticOption),
                );

                if (state.inputValue !== '') {
                    newOptions.push({
                        prompt: state.inputValue,
                        value: state.inputValue as any,
                        disabled: false,
                        hint: 'Current value',
                    });
                }
                return newOptions;
            }}
            renderOption={(props, option: SuggestionPropertyOption) => (
                <SelectOptionText {...props} option={option} key={props.id} />
            )}
            freeSolo
            disableClearable
            blurOnSelect
            value={currentOption}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    margin="dense"
                    error={props.error}
                    helperText={props.hint ?? <span>&nbsp;</span>}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            startAdornment: props.error ? (
                                <InputAdornment position="start">
                                    <Error color="error" />
                                </InputAdornment>
                            ) : (
                                props.warning && (
                                    <InputAdornment position="start">
                                        <RemapPalette from="warning" to="error">
                                            <WarningOutlined color="error" fontSize="small" />
                                        </RemapPalette>
                                    </InputAdornment>
                                )
                            ),
                        },
                    }}
                />
            )}
            onInputChange={(_event, value, reason) => {
                if (reason === 'input') {
                    currentInputValue.current = value;
                }
            }}
            onChange={(_event, option) => {
                currentInputValue.current = null;
                if (typeof option === 'string') {
                    props.onChange(option);
                } else if (option && typeof option === 'object') {
                    props.onChange(option.value);
                } else if (option === null) {
                    props.onChange('');
                }
            }}
            slots={{
                popper: PopperWithoutWidth,
            }}
            onBlur={() => {
                if (currentInputValue.current !== null) {
                    props.onChange(currentInputValue.current);
                }
            }}
        />
    );
}

function PopperWithoutWidth(props: PopperProps) {
    return (
        <Popper
            {...props}
            style={{
                ...props.style,
                width: undefined,
            }}
        />
    );
}
