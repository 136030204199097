import { Card, CardActionArea, CardActionAreaProps, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { ForwardedRef, forwardRef, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type GridCardProps<D extends React.ElementType, P> = {
    primaryText: ReactNode;
    typeText?: ReactNode;
    subtitle1Text?: ReactNode;
    subtitle2Text?: ReactNode;
    children?: ReactNode;
} & CardActionAreaProps<D, P>;

const GridCard = forwardRef(function GridCard_<D extends React.ElementType, P>(
    { component = 'div', primaryText, typeText, subtitle1Text, subtitle2Text, children, ...props }: GridCardProps<D, P>,
    ref: ForwardedRef<any>,
) {
    const { classes } = useStyles();
    return (
        <Grid size={{ sm: 4 }}>
            <Card className={classes.card} ref={ref}>
                <CardActionArea {...props} component={component}>
                    <CardContent>
                        {typeText ? (
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                {typeText}
                            </Typography>
                        ) : null}
                        <Typography variant="h5">{primaryText}</Typography>
                        {subtitle2Text ? <Typography variant="subtitle2">{subtitle2Text}</Typography> : null}
                        {subtitle1Text ? <Typography variant="subtitle1">{subtitle1Text}</Typography> : null}
                        {children}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
});

export default GridCard;

const useStyles = makeStyles()({
    card: {
        height: '100%',
        '& > .MuiButtonBase-root': {
            height: '100%',
        },
    },
});

export function GridCardLink(props: GridCardProps<typeof Link, {}>) {
    return <GridCard component={Link} {...props} />;
}
