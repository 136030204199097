import { Box, IconButton } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import ReactFlow, {
    Panel,
    useNodesState,
    useEdgesState,
    useReactFlow,
    Node,
    Edge,
    useUpdateNodeInternals,
    Controls,
    Background,
    ReactFlowInstance,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Tooltip } from '@mui/material';
import { Close, RestorePage, ZoomOutMap } from '@mui/icons-material';

import { TextUpdaterNode } from './TextUpdaterNode';
import { DownloadButton } from './DownloadButton';
import { getLayoutedElements } from './helpers';
import { useSaveSchemaLayoutMutation, useSchemaLayoutQuery } from '../api';
import { Blueprint } from '../../../repository/models/Blueprint';
import { EntityNode } from './types';

export const LayoutFlow = ({
    blueprint,
    initNodes,
    initEdges,
    isFullScreenMode,
    onFullScreenModeToggle,
}: {
    blueprint: Blueprint;
    initNodes: Node<EntityNode>[];
    initEdges: Edge[];
    isFullScreenMode: boolean;
    onFullScreenModeToggle: () => void;
}) => {
    const { fitView } = useReactFlow();
    const updateNodeInternals = useUpdateNodeInternals();
    const [rfInstance, setRfInstance] = useState<ReactFlowInstance | null>(null);
    const [reset, setReset] = useState(false);
    const [saveSchemaLayout] = useSaveSchemaLayoutMutation();
    const { data: schemaLayout } = useSchemaLayoutQuery(blueprint);

    const layouted = useMemo(() => {
        const defaultViewport = rfInstance ? rfInstance.toObject().viewport : null;

        return getLayoutedElements(
            initNodes,
            initEdges,
            reset ? null : schemaLayout ? schemaLayout : null,
            defaultViewport,
        );
    }, [initNodes, initEdges, schemaLayout, reset, rfInstance]);

    const [nodes, setNodes, onNodesChange] = useNodesState<Node<EntityNode>[]>(layouted.nodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>(layouted.edges);

    const nodeTypes = useMemo(() => ({ textUpdater: TextUpdaterNode }), []);

    useEffect(() => {
        setNodes(layouted.nodes);
        layouted.nodes.forEach((node: Node<EntityNode>) => updateNodeInternals(node.id));
        setEdges(layouted.edges);

        window.requestAnimationFrame(() => {
            fitView();
        });
    }, [layouted.nodes, layouted.edges, fitView, setEdges, setNodes, updateNodeInternals]);

    const handleNodeDragStop = () => {
        if (rfInstance) {
            const flow = rfInstance.toObject();
            saveSchemaLayout({ blueprint, flow });
        }
    };

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            defaultViewport={{ zoom: 0.5, x: 0, y: 200 }}
            maxZoom={1}
            minZoom={0.5}
            fitView
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeDragStop={handleNodeDragStop}
            onInit={setRfInstance}
        >
            <Controls />
            <Background gap={25} />
            <Panel position="top-right">
                <Box display="flex">
                    {!reset ? (
                        <Tooltip title="Reset layout">
                            <IconButton onClick={() => setReset(true)}>
                                <RestorePage />
                            </IconButton>
                        </Tooltip>
                    ) : null}

                    <DownloadButton />

                    <Tooltip title={isFullScreenMode ? 'Close full screen' : 'Open in full screen'}>
                        <IconButton onClick={onFullScreenModeToggle}>
                            {isFullScreenMode ? <Close /> : <ZoomOutMap />}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Panel>
        </ReactFlow>
    );
};
