import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
   Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ForwardedRef } from 'react';
import { useAddEntityMutation } from './api';
import { Entity, EntityList } from '../../repository/models/Entity';
import BusyButton from '../../BusyButton';
import { Alert } from '@mui/material';
import ValidatedTextInput from '../../hal/forms/ValidatedTextInput';
import { resolveTemplateRequired } from '../../hal';
import { Textarea } from '../../ui/Textarea';

type AddEntityButtonProps = {
    entityRefList: EntityList;
    callback: (newEntity: Entity) => void;
};

export default React.forwardRef(function AddEntityButton(props: AddEntityButtonProps, ref: ForwardedRef<any>) {
    const { classes } = useStyles();

    const [entityName, setEntityName] = React.useState<string>('');
    const [entityDescription, setEntityDescription] = React.useState<string>('');
    const [showAddEntity, setShowAddEntity] = React.useState<boolean>(false);

    const [addEntity, { isLoading, isError, error }] = useAddEntityMutation();

    const handleShowAddEntity = () => {
        setEntityName('');
        setShowAddEntity(true);
    };
    const handleCloseAddEntity = () => {
        setShowAddEntity(false);
    };
    const handleConfirmAddEntity = async () => {
        const addEntityResponse = await addEntity({
            entityRefList: props.entityRefList,
            entityName,
            entityDescription,
        });

        if ('data' in addEntityResponse && addEntityResponse.data) {
            props.callback(addEntityResponse.data);
            setShowAddEntity(false);
            setEntityDescription('');
        }
    };

    const halPropertyName = resolveTemplateRequired(props.entityRefList, 'default').property('name');
    const halPropertyDescription = resolveTemplateRequired(props.entityRefList, 'default').property('description');

    return (
        <>
            <Dialog open={showAddEntity} onClose={handleCloseAddEntity}>
                <DialogTitle>Add Entity</DialogTitle>
                <DialogContent>
                    {isError && (
                        <Box mb={2}>
                            <Alert severity="error">{error?.message}</Alert>
                        </Box>
                    )}

                    <DialogContentText>Enter the name of the entity you wish to add.</DialogContentText>
                    <ValidatedTextInput
                        autoFocus
                        placeholder="my-entity"
                        value={entityName}
                        halProperty={halPropertyName}
                        displayName="Entity name"
                        handleOnChange={(val) => setEntityName(val)}
                        handleOnKeyDown={(key) => key === 'Enter' && handleConfirmAddEntity()}
                        fullWidth
                    />

                    <Box marginTop={2}>
                        <DialogContentText>Enter the description of the entity (optional)</DialogContentText>

                        {halPropertyDescription ? (
                            <Textarea
                                value={entityDescription}
                                handleOnChange={(val) => setEntityDescription(val)}
                                handleOnKeyDown={(key) => key === 'Enter' && handleConfirmAddEntity()}
                            />
                        ) : null}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleCloseAddEntity} disabled={isLoading}>
                        Cancel
                    </Button>
                    <BusyButton variant="contained" busy={isLoading} onClick={handleConfirmAddEntity}>
                        Add
                    </BusyButton>
                </DialogActions>
            </Dialog>
            <Button
                variant="contained"
                color="primary"
                className={classes.addEntityButton}
                onClick={handleShowAddEntity}
                ref={ref}
            >
                Add Entity
            </Button>
        </>
    );
});

const useStyles = makeStyles()((theme: Theme) =>
    ({
        addEntityButton: {
            width: `calc(100% - ${theme.spacing(2)})`,
            margin: '0 auto 0 0',
        },
    }),
);
