import { Box, Button, Theme, Typography, styled } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useMemo, useReducer } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useNavigate } from 'react-router-dom';

import { IamRealm } from '../../../repository/models/IamRealm';
import { RForm, resolveTemplate } from '../../../hal';
import { useCreateRealmGroupMutation, useGetRealmGroupsQuery } from './../api';
import { inheritHalProperty } from '../../../hal/forms/react';
import ValidatedTextInput from '../../../hal/forms/ValidatedTextInput';
import { IAM_REALM_GROUPS } from '../../routes';
import { Organization } from '../../../repository/models/Organization';
import BusyButton from '../../../BusyButton';
import { ServerErrorMessage } from '../../../ui/ServerErrorMessage';
import { PageTitle } from '../../../ui/typography/PageTitle';
import IamGroupAttributesForm from './components/AttributesForm/AttributesForm';
import { RequestStateHandler } from '../../../ui/RequestStateHandler';

interface IamRealmCreateGroupProps {
    realm?: IamRealm;
    org: Organization | null;
}

const defaultState = {
    name: '',
    attributes: {},
};

export const IamRealmCreateGroup = ({ realm, org }: IamRealmCreateGroupProps) => {
    const {
        data: groupList,
        isLoading: groupListLoading,
        error: groupListError,
    } = useGetRealmGroupsQuery(realm?._links?.groups?.href ?? skipToken);
    const [createGroup, { error, isLoading }] = useCreateRealmGroupMutation();
    const navigate = useNavigate();

    const createGroupTemplate = useMemo(() => {
        if (!!groupList) {
            return resolveTemplate(groupList!, 'default');
        }
        return null;
    }, [groupList]);

    const [formState, setFormState] = useReducer((state: typeof defaultState, action: Partial<typeof defaultState>) => {
        return { ...state, ...action };
    }, defaultState);

    const handleSubmit = () => {
        createGroup({ templateGroup: groupList!, group: formState! }).then((response) => {
            if ('data' in response) {
                navigate(
                    IAM_REALM_GROUPS.generate({
                        org: org?.name!,
                        realm: realm?.id!,
                    }),
                );
            }
        });
    };

    const { classes } = useStyles();

    return (
        <>
            <PageTitle>Create group</PageTitle>
            {!!error ? <ServerErrorMessage error={error} /> : null}
            <Box marginTop={2}>
                {groupListLoading || !!groupListError ? (
                    <RequestStateHandler isLoading={groupListLoading} error={groupListError} />
                ) : createGroupTemplate !== null ? (
                    <RForm template={createGroupTemplate}>
                        <StyledWrapper>
                            <Typography className={classes.text} variant="body2" noWrap>
                                Name
                            </Typography>
                            <HalValidatedTextInput
                                displayName="Name"
                                name="name"
                                fullWidth
                                autoFocus
                                value={formState.name}
                                handleOnChange={(val) => setFormState({ name: val })}
                            />
                        </StyledWrapper>
                        <StyledWrapper>
                            <Typography className={classes.text} variant="body2" noWrap>
                                Attributes
                            </Typography>
                            <IamGroupAttributesForm
                                template={createGroupTemplate}
                                name="attributes"
                                value={formState.attributes}
                                onChange={(value) => setFormState({ attributes: value })}
                            />
                        </StyledWrapper>
                        <Box display="flex" justifyContent="flex-end">
                            <Box marginRight={2}>
                                <Button
                                    color="inherit"
                                    onClick={() =>
                                        navigate(
                                            IAM_REALM_GROUPS.generate({
                                                org: org?.name!,
                                                realm: realm?.id!,
                                            }),
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <BusyButton
                                busy={isLoading}
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Create group
                            </BusyButton>
                        </Box>
                    </RForm>
                ) : null}
            </Box>
        </>
    );
};

const HalValidatedTextInput = inheritHalProperty(ValidatedTextInput);

const StyledWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
}));

const useStyles = makeStyles()((theme: Theme) => ({
    text: {
        width: theme.spacing(16),
    },
}));
