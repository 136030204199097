import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
interface FormActionsProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    children?: React.ReactNode;
}

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *:not(:first-child)': {
            marginLeft: theme.spacing(1),
        },
    },
}));

const FormActions = React.forwardRef((props: FormActionsProps, ref: React.ForwardedRef<any>) => {
    const { classes, cx } = useStyles();
    const { className, ...other } = props;

    return <div ref={ref} className={cx(classes.root, className)} {...other} />;
});

export default FormActions;
