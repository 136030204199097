import React from 'react';
import ClockIcon from '@mui/icons-material/WatchLater';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { theme } from '../../../theme';
import { DeploymentStatus } from '../../../repository/models/Deployment';
import { SvgIconProps } from '@mui/material';

export const ApplicationDeploymentIcon = ({
    status,
    fontSize = 'inherit',
}: {
    status: DeploymentStatus;
    fontSize?: SvgIconProps['fontSize'];
}) => {
    if (status.failed) {
        return <ErrorIcon fontSize={fontSize} htmlColor={theme.palette.error.main} />;
    }

    if (status.finished) {
        return <CheckCircleIcon fontSize={fontSize} htmlColor={theme.palette.success.main} />;
    }

    return <ClockIcon fontSize={fontSize} htmlColor={theme.palette.primary.main} />;
};
