import { CircularProgress, styled } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import React from 'react';
import { ServerErrorMessage } from './ServerErrorMessage';

interface ApplicationHeaderInfoProps {
    name: string;
    zoneName: string | null;
    isLoading: boolean;
    error: SerializedError | undefined;
}

export const ApplicationHeaderInfo = ({ name, zoneName, isLoading, error }: ApplicationHeaderInfoProps) => {
    return (
        <StyledNameWrapper>
            <StyledName>{name}</StyledName>

            {isLoading ? (
                <CircularProgress disableShrink size="1em" />
            ) : !!error ? (
                <ServerErrorMessage textView error={error} />
            ) : zoneName ? (
                <StyledZoneName>{zoneName}</StyledZoneName>
            ) : null}
        </StyledNameWrapper>
    );
};

const StyledNameWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});
const StyledName = styled('span')({
    fontWeight: 700,
});
const StyledZoneName = styled('span')({
    fontStyle: 'italic',
});
