import {
    Box,
    Typography,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import BusyButton from '../../BusyButton';
import { Textarea } from '../Textarea';
import React from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { ServerErrorMessage } from '../ServerErrorMessage';

interface EditableDescriptionInputProps {
    readonly originalValue: string;
    readonly value: string;
    readonly onChange: (value: string) => void;

    readonly onEditRequest?: () => void;
    readonly onEditCancel: () => void;
    readonly onEditSave: (value: string) => Promise<void> | void;
    readonly editing: boolean;
    readonly editIsSaving: boolean;
    readonly editSaveError: SerializedError | undefined;
}
export default function EditableDescriptionInput(props: EditableDescriptionInputProps) {
    return (
        <>
            <Box marginTop={1} onClick={props.onEditRequest}>
                <Typography component="span" color="textSecondary" style={{overflowWrap : "break-word"}}>
                    {props.originalValue || 'Click to add a description'}&ensp;
                </Typography>
                <Box component="span" position="relative" top={-2}>
                    <IconButton size="small" onClick={props.onEditRequest}>
                        <Edit fontSize="inherit" color="disabled" />
                    </IconButton>
                </Box>
            </Box>

            <Dialog open={props.editing} onClose={props.onEditCancel}>
                <DialogTitle>Edit Entity Description</DialogTitle>
                <DialogContent>
                    <ServerErrorMessage error={props.editSaveError} />
                    <Textarea value={props.value} autoFocus handleOnChange={props.onChange} />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={props.onEditCancel} disabled={props.editIsSaving}>
                        Cancel
                    </Button>
                    <BusyButton
                        busy={props.editIsSaving}
                        color="primary"
                        variant="contained"
                        onClick={() => props.onEditSave(props.value)}
                    >
                        Save
                    </BusyButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
