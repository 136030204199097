import { Checkbox, FormControlLabel, Theme } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { EventHandlerEditFormRow } from './EventHandlerEditFormRow';
import { EventTypes } from '../types';
import { eventList } from '../helpers';
import cx from 'classnames';

interface EventHandlerFormEventTypesProps {
    name: string;
    events: EventTypes;
    onChange: (value: EventTypes) => void;
}

export const EventHandlerFormEventTypes = ({ name, events, onChange }: EventHandlerFormEventTypesProps) => {
    const handleChangeCheckbox = (checked: boolean, event: keyof EventTypes) => {
        onChange({
            ...events,
            [event]: checked,
        });
    };

    const handleKeyDown = (event: React.KeyboardEvent): void => {
        const currentValue = (event.target as HTMLInputElement).value as keyof EventTypes;
        const isEnter = event.key === 'Enter';
        if (isEnter) {
            onChange({
                ...events,
                [currentValue]: events[currentValue] ? false : true,
            });
        }
    };

    return (
        <EventHandlerEditFormRow label={name}>
            {eventList.map((event) => {
                return (
                    <FormControlLabel
                        key={event}
                        label={event}
                        value={event}
                        checked={events[event as keyof EventTypes]}
                        control={
                            <Checkbox color="primary" icon={<CheckboxIcon />} checkedIcon={<CheckboxIcon filled />} />
                        }
                        onKeyDown={handleKeyDown}
                        onChange={(_event, checked) => handleChangeCheckbox(checked, event as keyof EventTypes)}
                    />
                );
            })}
        </EventHandlerEditFormRow>
    );
};

interface CheckboxIconProps {
    filled?: boolean;
}
const CheckboxIcon = ({ filled }: CheckboxIconProps) => {
    const { classes } = useStyles();
    const checkboxClasses = cx(classes.checkbox, {
        [classes.filledCheckbox]: filled,
    });

    return <span className={checkboxClasses} />;
};

const useStyles = makeStyles()((theme: Theme) =>
    ({
        checkbox: {
            width: '1.25rem',
            height: '1.25rem',
            borderRadius: '0.25rem',
            border: '1px solid #cdd',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        filledCheckbox: {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
            position: 'relative',
            '&::before': {
                content: '""',
                width: '12px',
                height: '7px',
                borderBottom: '2px solid #fff',
                borderLeft: '2px solid #fff',
                transform: 'rotate(-50deg)',
                position: 'absolute',
                top: '4px',
                left: 0,
                right: 0,
                margin: '0 auto',
            },
        },
    }),
);
