import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { AppDispatch, AppState } from '../../../store';
import { refuseAssistanceThunk, requestAssistanceThunk } from './datamodelAssistantSlice';
import { selectCurrentBlueprint } from '../../blueprint/blueprintSlice';

type AssistantWelcomePopupProps = {
    onAssistanceRefused: () => void;
    onRequestAssistance: () => void;
};

const AssistantWelcomePopup: React.FC<AssistantWelcomePopupProps> = ({ onAssistanceRefused, onRequestAssistance }) => {
    const { classes } = useStyles();

    return (
        <Box>
            <Typography className={classes.bottomMarginText}>
                The ContentGrid Assistant is here to help you. Use our AI assistance to set up your datamodel.
            </Typography>
            <Grid container justifyContent="flex-end" className={classes.buttons}>
                <Button variant="text" onClick={onAssistanceRefused}>
                    Dismiss
                </Button>
                <Button variant="contained" onClick={onRequestAssistance}>
                    Ask Assistance
                </Button>
            </Grid>
        </Box>
    );
};

const useStyles = makeStyles()((theme) => ({
    bottomMarginText: {
        marginBottom: theme.spacing(3),
    },
    buttons: {
        marginBottom: theme.spacing(2),
        gap: theme.spacing(1),
    },
}));

export default connect(
    (state: AppState) => ({
        blueprint: selectCurrentBlueprint(state),
    }),
    (dispatch: AppDispatch) => ({
        onRequestAssistance: () => dispatch(requestAssistanceThunk()),
        onAssistanceRefused: () => dispatch(refuseAssistanceThunk(true)),
    }),
)(AssistantWelcomePopup);
