import React from 'react';
import { Button, Tooltip, Typography } from '@mui/material';
import RelativeTime from '@yaireo/relative-time';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';

import { BlueprintOverview } from '../../repository/models/BlueprintOverview';
import { BLUEPRINT_ROOT, RELEASE_CREATE } from '../routes';
import { useGetBlueprintFeaturesQuery, useGetBlueprintOverviewsQuery, useGetBlueprintQuery } from './api';
import { blueprintSelectLocation, HighlightedComponent, releasesCreateLocation } from '../tour';
import { Row } from '../../ui/table/Row';
import { TableHeader } from '../../ui/typography/TableHeader';
import { Cell } from '../../ui/table/Cell';
import { RequestStateHandler } from '../../ui/RequestStateHandler';
import { selectCurrentOrganization } from '../account/accountSlice';
import { selectCurrentProject } from '../project/projectSlice';
import { selectCurrentBlueprint } from './blueprintSlice';
import { AppState } from '../../store';
import { Blueprint } from '../../repository/models/Blueprint';
import { Organization } from '../../repository/models/Organization';
import { Project } from '../../repository/models/Project';

function compareRecency(a: BlueprintOverview, b: BlueprintOverview) {
    if (a.updated === null) {
        return 1;
    }
    if (b.updated === null) {
        return -1;
    }
    return new Date(b.updated).getTime() - new Date(a.updated).getTime();
}

interface BlueprintsTabProps {
    currentBlueprint: Blueprint | null;
    currentOrganization: Organization | null;
    currentProject: Project | null;
}

function BlueprintsTab(props: BlueprintsTabProps) {
    const {
        data: blueprints,
        isLoading,
        error,
    } = useGetBlueprintOverviewsQuery(props.currentProject ?? skipToken, {
        refetchOnMountOrArgChange: true,
    });

    const relativeTime = new RelativeTime();

    if (isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />;
    }

    return (
        <div>
            <Typography variant="body2">
                A <b>blueprint</b> describes the design of your application: data model, permissions and webhooks.
            </Typography>

            <Row>
                <TableHeader width={15}>{/* Select buttons */}</TableHeader>
                <TableHeader width={20}>Name</TableHeader>
                <TableHeader width={10}>Version</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader width={15}>Last Updated</TableHeader>
                <TableHeader width={21}>{/* Publish buttons */}</TableHeader>
            </Row>

            {(blueprints ?? [])
                .slice()
                .sort(compareRecency)
                .map((blueprint: BlueprintOverview, i: number) => {
                    const updated = blueprint.updated === null ? null : new Date(blueprint.updated);

                    return (
                        <Row borders="horizontal" isNotFirstDescendant={i !== 0} key={blueprint.ref + ':' + i}>
                            <Cell width={15}>
                                <HighlightedComponent
                                    location={blueprintSelectLocation}
                                    disabled={props.currentBlueprint?.ref === blueprint.ref}
                                >
                                    {props.currentBlueprint?.ref === blueprint.ref ? (
                                        <Button variant="outlined" size="small" color="primary" disabled>
                                            Selected
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            component={RouterLink}
                                            size="small"
                                            color="primary"
                                            to={BLUEPRINT_ROOT.generate({
                                                org: props.currentOrganization!.name,
                                                project: props.currentProject!.name,
                                                blueprint: blueprint.name,
                                            })}
                                        >
                                            Select
                                        </Button>
                                    )}
                                </HighlightedComponent>
                            </Cell>
                            <Cell width={20}>{blueprint.name}</Cell>
                            <Cell width={10}>{blueprint.latest_release ? <>{blueprint.latest_release}</> : '-'}</Cell>
                            <Cell>
                                {blueprint.unreleased_changes === 0
                                    ? 'Published'
                                    : blueprint.unreleased_changes + ' unreleased changes'}
                            </Cell>
                            <Cell width={15}>
                                {updated ? (
                                    <Tooltip title={updated.toLocaleString()}>
                                        <span>{relativeTime.from(updated)}</span>
                                    </Tooltip>
                                ) : (
                                    '-'
                                )}
                            </Cell>
                            <Cell width={21}>
                                <ManageButton {...props} blueprintOverview={blueprint} />
                            </Cell>
                        </Row>
                    );
                })}
        </div>
    );
}

export default connect((state: AppState) => ({
    currentOrganization: selectCurrentOrganization(state),
    currentProject: selectCurrentProject(state),
    currentBlueprint: selectCurrentBlueprint(state),
}))(BlueprintsTab);

const CreateReleaseButton = (props: ManageButtonProps) => {
    const { classes } = useStyles();

    return (
        <HighlightedComponent location={releasesCreateLocation}>
            <Button
                className={classes.button}
                color="secondary"
                variant="outlined"
                component={RouterLink}
                size="small"
                to={RELEASE_CREATE.generate({
                    org: props.currentOrganization!.name,
                    project: props.currentProject!.name,
                    blueprint: props.blueprintOverview.name,
                })}
            >
                Release
            </Button>
        </HighlightedComponent>
    );
};

interface ManageButtonProps extends BlueprintsTabProps {
    blueprintOverview: BlueprintOverview;
}

const ManageButton = (props: ManageButtonProps) => {
    const { data: blueprint } = useGetBlueprintQuery(props.blueprintOverview);

    if (props.blueprintOverview.unreleased_changes > 0) {
        return <CreateReleaseButton {...props} />;
    }

    if (blueprint) {
        return <ManageFeaturesButton {...props} blueprint={blueprint} blueprintOverview={props.blueprintOverview} />;
    }

    return null;
};

interface ManageFeaturesButtonProps extends BlueprintsTabProps {
    blueprint: Blueprint;
    blueprintOverview: BlueprintOverview;
}

const ManageFeaturesButton = (props: ManageFeaturesButtonProps) => {
    const { data: features } = useGetBlueprintFeaturesQuery(props.blueprint);

    if (features?.length) {
        return <CreateReleaseButton {...props} />;
    }

    return null;
};

const useStyles = makeStyles()(() =>
    ({
        button: {
            margin: '0 0 0 auto',
        }
    }));
