import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactNode } from 'react';

export interface RowProps {
    children: ReactNode;
    borders?: 'all' | 'horizontal' | 'none';
    isNotFirstDescendant?: boolean;
    onClick?: () => void;
}

export const Row = ({ children, borders = 'none', isNotFirstDescendant = true, onClick }: RowProps) => {
    const { classes, cx } = useStyles();
    const rowClasses = cx(classes.row, {
        [classes.allBorders]: borders === 'all',
        [classes.horizontalBorders]: borders === 'horizontal',
        [classes.isNotFirst]: isNotFirstDescendant && borders !== 'none',
    });

    return (
        <div className={rowClasses} onClick={onClick}>
            {children}
        </div>
    );
};

const useStyles = makeStyles()((_theme: Theme) =>
    ({
        row: {
            display: 'flex',
            alignItems: 'center',
        },
        allBorders: {
            border: `1px solid ${_theme.palette.grey[300]}`,
        },
        horizontalBorders: {
            borderTop: `1px solid ${_theme.palette.grey[300]}`,
            borderBottom: `1px solid ${_theme.palette.grey[300]}`,
        },
        isNotFirst: {
            borderTopWidth: '0px',
        },
    }),
);
