import {
    Box,
    Dialog,
    DialogActions,
    Table,
    TableCell,
    TableRow,
    Typography,
    styled,
    Checkbox,
    DialogTitle,
    Button,
    TableHead,
    TableBody,
    FormControlLabel,
} from '@mui/material';
import React, { useState } from 'react';
import cx from 'classnames';

import { IamRealm } from '../../../../repository/models/IamRealm';
import { useAddRealmGroupMembersMutation, useGetRealmUsersQuery } from '../../api';
import { RequestStateHandler } from '../../../../ui/RequestStateHandler';
import { IamGroupMemberList } from '../../../../repository/models/IamGroup';
import BusyButton from '../../../../BusyButton';
import { IamUser, IamUserList } from '../../../../repository/models/IamUser';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { Alert } from '@mui/material';
import { TypedLink } from '../../../../hal';
import { IamUserName } from '../../components/IamUserName';
import { NavigationButtons } from '../../components/NavigationButtons';
import { Tooltip } from '@mui/material';
import { RemoveCircleOutline } from '@mui/icons-material';

interface MembersDialogProps {
    memberList: IamGroupMemberList | null;
    realm?: IamRealm;
    opened: boolean;
    onClose: () => void;
}

export const MembersDialog = ({ memberList, realm, opened, onClose }: MembersDialogProps) => {
    const [link, setLink] = useState<TypedLink<IamUserList>>();

    const { data: userList, isLoading, error } = useGetRealmUsersQuery(link ?? realm?._links?.users?.href ?? skipToken);

    const users = userList?._embedded?.users ?? [];

    const members = memberList?._embedded?.members ?? [];
    const [selectedUsers, setselectedUsers] = useState<IamUser[]>([]);
    const [addMembers, { isLoading: isAddMembersLoading, error: errorAddMembers }] = useAddRealmGroupMembersMutation();

    const handleClick = async () => {
        if (!!selectedUsers.length && !!memberList) {
            await addMembers({
                memberList: memberList,
                users: selectedUsers,
            }).unwrap();
            handleClose();
        }
    };

    const handleChange = (user: IamUser) => {
        const userLink = user._links.self.href;
        if (!!selectedUsers.find((item) => item._links.self.href === userLink)) {
            setselectedUsers(selectedUsers.filter((item) => item._links.self.href !== userLink));
            return;
        }
        setselectedUsers([...selectedUsers, user]);
    };

    const handleClose = () => {
        setselectedUsers([]);
        onClose();
    };

    const handleNext = () => {
        if (!!userList?._links?.next?.href) {
            setLink(userList?._links?.next.href);
        }
    };

    const handlePrev = () => {
        if (!!userList?._links?.prev?.href) {
            setLink(userList?._links?.prev.href);
        }
    };

    return (
        <Dialog open={opened} maxWidth="lg" onClose={handleClose}>
            <DialogTitle>Add users to group</DialogTitle>
            <Box marginBottom={3}>
                {isLoading || !!error || !!errorAddMembers ? (
                    <RequestStateHandler isLoading={isLoading} error={error || errorAddMembers} />
                ) : !users.length ? (
                    <Alert severity="info">No users!</Alert>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Box paddingLeft={4}>
                                        <Typography variant="body2">
                                            <b>Username</b>
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        <b>Email</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        <b>Name</b>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((item) => {
                                const selectedBefore = !!members.find((member) => member.userId === item.userId);
                                const classes = cx({
                                    'is-disabled': selectedBefore,
                                });
                                const checked =
                                    !!selectedUsers.find((user) => user._links.self.href === item._links.self.href) ||
                                    selectedBefore;
                                return (
                                    <StyledRow className={classes} key={item.username}>
                                        <TableCell size="small">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        style={{ padding: 8 }}
                                                        checked={checked}
                                                        color="primary"
                                                        onClick={() => handleChange(item)}
                                                    />
                                                }
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="body2">{item.username}</Typography>
                                                        {!item.enabled ? (
                                                            <Box
                                                                display="flex"
                                                                marginLeft={1}
                                                                height="20px"
                                                                alignItems="center"
                                                            >
                                                                <Tooltip title="User is deactivated">
                                                                    <RemoveCircleOutline
                                                                        color="error"
                                                                        fontSize="small"
                                                                    />
                                                                </Tooltip>
                                                            </Box>
                                                        ) : null}
                                                    </Box>
                                                }
                                            />
                                        </TableCell>
                                        <TableCell size="small">
                                            <Typography variant="body2">{item.email}</Typography>
                                        </TableCell>
                                        <TableCell size="small">
                                            <Typography variant="body2">
                                                <IamUserName firstName={item?.first_name} lastName={item?.last_name} />
                                            </Typography>
                                        </TableCell>
                                    </StyledRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
            </Box>
            {!!userList?._links.next || !!userList?._links.prev ? (
                <NavigationButtons
                    prevDisabled={!userList?._links.prev?.href}
                    nextDisabled={!userList._links.next?.href}
                    onPrevClick={handlePrev}
                    onNextClick={handleNext}
                />
            ) : null}
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <BusyButton
                    color="primary"
                    busy={isAddMembersLoading}
                    variant="contained"
                    disableElevation
                    disabled={!selectedUsers.length}
                    onClick={handleClick}
                >
                    Add
                </BusyButton>
            </DialogActions>
        </Dialog>
    );
};

const StyledRow = styled(TableRow)({
    '&.is-disabled': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
});
