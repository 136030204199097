import React from 'react';
import { Theme, alpha, TextareaAutosize } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type TextareaProps = {
    placeholder?: string;
    value?: string;
    className?: string;
    id?: string;
    autoFocus?: boolean;
    onBlur?: () => void;
    handleOnChange?: (value: string) => void;
    handleOnKeyDown?: (key: string) => void;
};

export const Textarea = (props: TextareaProps) => {
    const { classes } = useStyles();

    return (
        <TextareaAutosize
            className={classes.teaxtarea}
            placeholder={props.placeholder}
            value={props.value}
            id={props.id}
            minRows={4}
            maxLength={200}
            autoFocus={props.autoFocus}
            onBlur={props.onBlur}
            onChange={(e) => {
                props.handleOnChange && props.handleOnChange(e.target.value);
            }}
            onKeyDown={(e) => {
                props.handleOnKeyDown && props.handleOnKeyDown(e.key);
            }}
        />
    );
};

const useStyles = makeStyles()((theme: Theme) =>
    ({
        teaxtarea: {
            padding: '.6em',
            border: '1px solid ' + theme.palette.divider,
            borderColor: theme.palette.grey[300],
            borderRadius: '.25em',
            width: '100%',
            fontSize: theme.typography.body1.fontSize,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            resize: 'none',
            '&:focus': {
                borderColor: theme.palette.primary.main,
                boxShadow: alpha(theme.palette.primary.main, 0.5) + ' 0 0 0px 3px',
            },
            '&:focus-visible': {
                borderColor: theme.palette.primary.main,
                boxShadow: alpha(theme.palette.primary.main, 0.5) + ' 0 0 0px 3px',
                outline: 'none',
            },
        },
    }),
);
