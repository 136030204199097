import { alpha, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import { Project } from '../../repository/models/Project';
import { AppDispatch, AppState } from '../../store';
import { projectSetCurrentProject, selectCurrentProject, selectProjects } from './projectSlice';

type ProjectSelectorProps = {
    projects: readonly Project[];
    currentProject: Project | null;
    handleSetCurrentProject: (project: Project) => void;
};

function ProjectSelector({ projects, currentProject, handleSetCurrentProject }: ProjectSelectorProps) {
    const { classes } = useStyles();

    const handleChange = useCallback(
        (event: SelectChangeEvent) => {
            let selectedName = event.target.value as string;
            let selected = projects.filter((p) => p.name === selectedName)[0] ?? null;
            if (selected) {
                handleSetCurrentProject(selected);
            }
        },
        [projects, handleSetCurrentProject],
    );

    if (currentProject === null) {
        return null;
    }

    return (
        <>
            <div className={classes.root}>
                {/* <InputBase
                placeholder="Select project…"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
            /> */}
                <Select
                    color="secondary"
                    variant="outlined"
                    className={classes.select}
                    value={currentProject?.name ?? ''}
                    onChange={handleChange}
                    displayEmpty
                >
                    {projects.map((project) => (
                        <MenuItem key={project.slug} value={project.name}>
                            {project.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </>
    );
}

const useStyles = makeStyles()((theme: Theme) =>
    ({
        root: {},
        select: {
            minWidth: 160,
            marginRight: theme.spacing(2),
            // color: theme.palette.primary.contrastText,

            '& .MuiOutlinedInput-input': {
                padding: '8px 37px 9px 12px',
                // backgroundColor: theme.palette.primary.light,
                backgroundColor: alpha('#fff', 0.9),
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: 0,
            },
            '& .MuiSelect-select:focus': {
                borderRadius: 'inherit',
            },
        },
    }),
);

export default connect(
    (state: AppState) => ({
        projects: selectProjects(state),
        currentProject: selectCurrentProject(state),
    }),
    (dispatch: AppDispatch) => ({
        handleSetCurrentProject: (project: Project) => dispatch(projectSetCurrentProject(project)),
    }),
)(ProjectSelector);
