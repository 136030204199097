import { Theme, ThemeProvider, useTheme } from '@mui/material';
import { PaletteColor } from '@mui/material/styles/createPalette';
import React from 'react';

type PaletteColorName = keyof {
    [key in keyof Theme['palette'] as Theme['palette'][key] extends PaletteColor ? key : never]: Theme['palette'][key];
};

interface RemapPaletteProps {
    readonly from: PaletteColorName;
    readonly to: PaletteColorName;
    readonly children: React.ReactNode;
}

export default function RemapPalette(props: RemapPaletteProps) {
    const theme = useTheme();
    return (
        <ThemeProvider
            theme={{
                ...theme,
                palette: {
                    ...theme.palette,
                    [props.to]: theme.palette[props.from],
                },
            }}
        >
            {props.children}
        </ThemeProvider>
    );
}
