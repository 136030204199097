import React, { useMemo } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import { Close } from '@mui/icons-material';

import { AttributeInput } from './components/AttributeInput';
import { HalForm } from '../../../../../hal/forms';
import { IamGroupAttributes } from '../../../../../repository/models/IamGroup';
import { emptyOfType } from '../../helpers';
import { AddAttributeRow } from './components/AddAttributes';
import { Row } from '../../../../../ui/table/Row';
import { Cell } from '../../../../../ui/table/Cell';

interface IamGroupAttributesFormProps {
    readonly template: HalForm<any>;
    readonly name: string;
    readonly value: IamGroupAttributes;
    readonly onChange: (value: IamGroupAttributes) => void;
}

export default function IamGroupAttributesForm(props: IamGroupAttributesFormProps) {
    const namePrefix = props.name + '.';
    const attributes = useMemo(
        () => props.template.properties.filter((p) => p.name.startsWith(namePrefix)),
        [props.template, namePrefix],
    );

    const [usedAttributes, unusedAttributes] = useMemo(() => {
        const usedAttributes: string[] = [];
        const unusedAttributes: string[] = [];

        for (const attr of attributes) {
            const attrName = attr.name.substring(namePrefix.length);
            if (props.value[attrName] === undefined) {
                unusedAttributes.push(attrName);
            } else {
                usedAttributes.push(attrName);
            }
        }

        return [usedAttributes, unusedAttributes];
    }, [attributes, props.value, namePrefix]);

    const handleChange = (name: string) => {
        const { type } = props.template.property(namePrefix + name);
        props.onChange({
            ...props.value,
            [name]: emptyOfType(type ?? 'text[]'),
        });
    };

    return (
        <StyledWrapper>
            {!!usedAttributes.length ? (
                usedAttributes.map((attrName) => {
                    const currentValue = props.value[attrName];
                    const attr = props.template.property(namePrefix + attrName);
                    return (
                        <Row key={attrName} borders="horizontal">
                            <Cell isLarge width={37}>
                                <StyledAttribute variant="body2">
                                    <b>{attrName}</b>
                                    <StyledIconButton
                                        size="small"
                                        onClick={() =>
                                            props.onChange({
                                                ...props.value,
                                                [attrName]: undefined!,
                                            })
                                        }
                                    >
                                        <Close />
                                    </StyledIconButton>
                                </StyledAttribute>
                            </Cell>
                            <StyledCell>
                                <AttributeInput
                                    type={attr.type ?? 'text[]'}
                                    value={currentValue as any}
                                    onChange={(value) =>
                                        props.onChange({
                                            ...props.value,
                                            [attrName]: value!,
                                        })
                                    }
                                />
                            </StyledCell>
                        </Row>
                    );
                })
            ) : (
                <Row borders="horizontal">
                    <Cell isLarge>
                        <Typography variant="body2">No attributes</Typography>
                    </Cell>
                </Row>
            )}
            <AddAttributeRow attributes={unusedAttributes} onChange={handleChange} />
        </StyledWrapper>
    );
}

const StyledWrapper = styled('div')(({ theme }) => ({
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
}));

const StyledAttribute = styled(Typography)(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    minHeight: theme.spacing(4),
}));

const StyledCell = styled('div')(({ theme }) => ({
    flexWrap: 'wrap',
    padding: theme.spacing(1, 2),
    display: 'flex',
    flex: '1 0',
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: theme.spacing(0.25),
    '& .MuiSvgIcon-root': {
        fontSize: theme.typography.fontSize,
    },
}));
