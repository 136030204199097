import { Box, Button, FormControlLabel, Typography, Checkbox as UICheckbox, styled } from '@mui/material';
import React, { useMemo, useReducer } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';

import { IamRealm } from '../../../repository/models/IamRealm';
import { RForm, resolveTemplate } from '../../../hal';
import { useCreateRealmUserMutation, useGetRealmUsersQuery } from '../api';
import { inheritHalProperty, withHalProperty } from '../../../hal/forms/react';
import ValidatedTextInput from '../../../hal/forms/ValidatedTextInput';
import { useNavigate } from 'react-router-dom';
import { IAM_REALM_USERS } from '../../routes';
import { Organization } from '../../../repository/models/Organization';
import BusyButton from '../../../BusyButton';
import { ServerErrorMessage } from '../../../ui/ServerErrorMessage';
import { PageTitle } from '../../../ui/typography/PageTitle';
import { RequestStateHandler } from '../../../ui/RequestStateHandler';

interface IamRealmCreateUserProps {
    realm?: IamRealm;
    org: Organization | null;
}

const defaultState = {
    email: '',
    first_name: '',
    last_name: '',
    send_credentials_setup_email: true,
};

export const IamRealmCreateUser = ({ realm, org }: IamRealmCreateUserProps) => {
    const {
        data: userList,
        isLoading: userLoading,
        error: userError,
    } = useGetRealmUsersQuery(realm?._links?.users?.href ?? skipToken);
    const [createUser, { error, isLoading }] = useCreateRealmUserMutation();
    const navigate = useNavigate();

    const userTemplate = useMemo(() => {
        if (!!userList) {
            return resolveTemplate(userList!, 'default');
        }
        return null;
    }, [userList]);

    const [formState, setFormState] = useReducer((state: typeof defaultState, action: Partial<typeof defaultState>) => {
        return { ...state, ...action };
    }, defaultState);

    const handleSubmit = () => {
        createUser({ templateUser: userList!, user: formState! }).then((response) => {
            if ('data' in response) {
                navigate(
                    IAM_REALM_USERS.generate({
                        org: org?.name!,
                        realm: realm?.id!,
                    }),
                );
            }
        });
    };

    return (
        <>
            <PageTitle>Create user</PageTitle>
            {!!error ? <ServerErrorMessage error={error} /> : null}
            <Box marginTop={2}>
                {userLoading || !!userError ? (
                    <RequestStateHandler isLoading={userLoading} error={userError} />
                ) : userTemplate !== null ? (
                    <RForm template={userTemplate}>
                        <StyledWrapper>
                            <Typography variant="body2" noWrap component="label" htmlFor="email">
                                Email
                            </Typography>
                            <HalValidatedTextInput
                                displayName="Email"
                                name="email"
                                id="email"
                                fullWidth
                                value={formState.email}
                                handleOnChange={(val) => setFormState({ email: val })}
                            />
                        </StyledWrapper>
                        <StyledWrapper>
                            <Typography variant="body2" noWrap component="label" htmlFor="first_name">
                                First name
                            </Typography>
                            <HalValidatedTextInput
                                displayName="First name"
                                name="first_name"
                                id="first_name"
                                fullWidth
                                value={formState.first_name}
                                handleOnChange={(val) => setFormState({ first_name: val })}
                            />
                        </StyledWrapper>
                        <StyledWrapper>
                            <Typography variant="body2" noWrap component="label" htmlFor="last_name">
                                Last name
                            </Typography>
                            <HalValidatedTextInput
                                displayName="Last name"
                                name="last_name"
                                id="last_name"
                                fullWidth
                                value={formState.last_name}
                                handleOnChange={(val) => setFormState({ last_name: val })}
                            />
                        </StyledWrapper>
                        <StyledWrapper>
                            <Box marginLeft={14}></Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="send_credentials_setup_email"
                                        checked={formState.send_credentials_setup_email}
                                        onChange={(e) =>
                                            setFormState({
                                                send_credentials_setup_email: e.target.checked,
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography component="span" variant="body2" noWrap>
                                        Send credentials setup email
                                    </Typography>
                                }
                            />
                        </StyledWrapper>
                        <Box display="flex" justifyContent="flex-end">
                            <Box marginRight={2}>
                                <Button
                                    color='inherit'
                                    onClick={() =>
                                        navigate(
                                            IAM_REALM_USERS.generate({
                                                org: org?.name!,
                                                realm: realm?.id!,
                                            }),
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <BusyButton
                                busy={isLoading}
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Create user
                            </BusyButton>
                        </Box>
                    </RForm>
                ) : null}
            </Box>
        </>
    );
};

const HalValidatedTextInput = inheritHalProperty(ValidatedTextInput);
const Checkbox = withHalProperty(UICheckbox);

const StyledWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& .MuiTypography-root': {
        width: theme.spacing(16),
    },
    '& .MuiFormControlLabel-root .MuiTypography-body2.MuiTypography-root': {
        width: '100%',
    },
}));
