import React from 'react';
import { Button, styled, Box, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';

import { IamGroupAttributes } from '../../../../../../repository/models/IamGroup';
import { emptyOfType } from '../../../helpers';
import { ControlsWrapper } from '../../../../../../ui/input/ControlsWrapper';
import TextInput from '../../../../../../ui/input/TextInput';
import { MenuProps } from '@mui/material';
import { StyledSelect } from '../../../../../../ui/styles/StyledSelect';
import RemapPalette from '../../../../../../ui/theme/RemapPalette';
import { Close } from '@mui/icons-material';

interface AttributeInputProps {
    readonly type: string;
    readonly value?: IamGroupAttributes[''];
    readonly onChange: (value: IamGroupAttributes[''] | undefined) => void;
}

export const AttributeInput = (props: AttributeInputProps) => {
    if (props.type.endsWith('[]')) {
        const baseType = props.type.substring(0, props.type.length - 2);
        const valueAsArray = (props.value ?? []) as readonly any[];

        const handleChange = (i: number, value?: string | number | boolean) => {
            if (value !== undefined) {
                props.onChange([...valueAsArray.slice(0, i), value, ...valueAsArray.slice(i + 1)]);
                return;
            }
            props.onChange([...valueAsArray.slice(0, i), ...valueAsArray.slice(i + 1)]);
        };

        return (
            <>
                {valueAsArray.map((value, i) => (
                    <StyledInputWrapper key={i}>
                        <SingleInput
                            type={baseType}
                            value={value}
                            onChange={(val) => handleChange(i, val)}
                            onRemove={() => handleChange(i)}
                        />
                    </StyledInputWrapper>
                ))}

                <StyledButton
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => props.onChange([...valueAsArray, emptyOfType(baseType)])}
                >
                    Add item
                </StyledButton>
            </>
        );
    }
    return (
        <Box width="100%" marginY={1}>
            <SingleInput
                isSingle
                type={props.type}
                value={props.value as any}
                onChange={(value) => props.onChange(value)}
            />
        </Box>
    );
};

interface SingleInputProps {
    readonly type: string;
    readonly value: string | number | boolean | null | undefined;
    readonly isSingle?: boolean;
    readonly onChange: (value: string | number | boolean) => void;
    readonly onRemove?: () => void;
}

function SingleInput(props: SingleInputProps) {
    const control = props.isSingle ? null : (
        <RemapPalette from="danger" to="primary">
            <Button variant="outlined" color="primary" onClick={props.onRemove}>
                <Close fontSize="inherit" color="inherit" />
            </Button>
        </RemapPalette>
    );

    switch (props.type) {
        case 'text':
            return (
                <ControlsWrapper>
                    <TextInput value={(props.value as string) ?? ''} autoFocus handleOnChange={props.onChange} />
                    {control}
                </ControlsWrapper>
            );
        case 'number':
            return (
                <ControlsWrapper>
                    <TextInput
                        value={(props.value as string) ?? '0'}
                        autoFocus
                        type="number"
                        handleOnChange={(val) => props.onChange(Number(val))}
                    />
                    {control}
                </ControlsWrapper>
            );
        case 'checkbox':
            return (
                <ControlsWrapper>
                    <BooleanSelect value={!!props.value} onChange={props.onChange} />
                    {control}
                </ControlsWrapper>
            );
    }
    return null;
}

const menuSettings = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
} as Partial<MenuProps>;

interface BooleanSelectProps {
    value: boolean;
    onChange: (val: boolean) => void;
}

const BooleanSelect = ({ value, onChange }: BooleanSelectProps) => {
    const handleChange = (e: SelectChangeEvent<unknown>) => {
        const value = e.target.value;
        onChange(value === 'true' ? true : false);
    };

    return (
        <FormControl size="small">
            <StyledSelect
                variant="outlined"
                value={!!value ? 'true' : 'false'}
                MenuProps={menuSettings}
                onChange={handleChange}
            >
                <StyledMenuItem value="true">true</StyledMenuItem>
                <StyledMenuItem value="false">false</StyledMenuItem>
            </StyledSelect>
        </FormControl>
    );
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: theme.typography.fontSize,
}));

const StyledInputWrapper = styled('div')(({ theme }) => ({
    margin: theme.spacing(1, 2, 1, 0),
    maxWidth: theme.spacing(25),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1, 0),
}));
