import { skipToken } from '@reduxjs/toolkit/query/react';
import React, { useMemo, useState } from 'react';
import { Box, Button, styled, Typography } from '@mui/material';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

import { Organization } from '../../../repository/models/Organization';
import { useGetRealmGroupsQuery } from '../api';
import { IamRealm } from '../../../repository/models/IamRealm';
import { IamTable, StyledIamNavLink } from '../../../ui/table/IamTable';
import { Cell } from '../../../ui/table/Cell';
import { TableHeader } from '../../../ui/typography/TableHeader';
import { Row } from '../../../ui/table/Row';
import { IAM_REALM_CREATE_GROUP, IAM_REALM_GROUP } from '../../routes';
import { IamGroup, IamGroupList } from '../../../repository/models/IamGroup';
import { TypedLink, resolveTemplate } from '../../../hal';
import { NavigationButtons } from '../components/NavigationButtons';
import { makeStyles } from 'tss-react/mui';

interface IamRealmGroupsProps {
    org: Organization | null;
    realm?: IamRealm;
}

export const IamRealmGroups = ({ org, realm }: IamRealmGroupsProps) => {
    const [link, setLink] = useState<TypedLink<IamGroupList>>();
    const {
        data: groupList,
        isLoading,
        error,
    } = useGetRealmGroupsQuery(link ?? realm?._links?.groups?.href ?? skipToken);
    const groups = groupList?._embedded?.groups ?? [];
    const groupsAttributes = groupList?._embedded?.groups as IamGroup[];

    const createGroupTemplate = useMemo(() => {
        if (!!groupList) {
            return resolveTemplate(groupList!, 'default');
        }
        return null;
    }, [groupList]);

    const handleNext = () => {
        if (!!groupList?._links?.next?.href) {
            setLink(groupList?._links?.next.href);
        }
    };

    const handlePrev = () => {
        if (!!groupList?._links?.prev?.href) {
            setLink(groupList?._links?.prev.href);
        }
    };

    const { classes } = useStyles();
    return (
        <>
            {!!createGroupTemplate && (
                <Box marginBottom={2} display="flex" justifyContent="flex-end">
                    <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={IAM_REALM_CREATE_GROUP.generate({
                            org: org?.name!,
                            realm: realm?.id!,
                        })}
                    >
                        Create group
                    </Button>
                </Box>
            )}

            <IamTable
                isLoading={isLoading || !org}
                error={error}
                noRecords={groups.length === 0}
                noRecordsText="No groups."
                headers={
                    <>
                        <StyledDiv>
                            <TableHeader>Group name</TableHeader>
                        </StyledDiv>
                        <TableHeader>Attributes</TableHeader>
                    </>
                }
            >
                {(groups ?? []).map((group) => {
                    const groupAttributes = groupsAttributes.find((g) => g.groupId === group.groupId)?.attributes;

                    return (
                        <Row key={group._links.self.href} borders="horizontal">
                            <StyledDiv>
                                <Cell>
                                    <StyledIamNavLink
                                        to={IAM_REALM_GROUP.generate({
                                            org: org!.name,
                                            realm: realm?.id ?? '',
                                            group: group.groupId,
                                        })}
                                    >
                                        {group.name}
                                    </StyledIamNavLink>
                                </Cell>
                            </StyledDiv>

                            {groupAttributes && (
                                <Cell>
                                    <div>
                                        {Object.entries(groupAttributes).map(([key, value], index) => (
                                            <StyledBox key={index}>
                                                <NoMaxWidthTooltip title={key}>
                                                    <Typography className={classes.tableKey}>{key}</Typography>
                                                </NoMaxWidthTooltip>
                                                <Typography sx={{ marginRight: '1rem' }}>:</Typography>
                                                <NoMaxWidthTooltip title={String(value)}>
                                                    <Typography className={classes.tableValue}>
                                                        {String(value)}
                                                    </Typography>
                                                </NoMaxWidthTooltip>
                                            </StyledBox>
                                        ))}
                                    </div>
                                </Cell>
                            )}
                        </Row>
                    );
                })}
            </IamTable>

            {!!groupList?._links.next || !!groupList?._links.prev ? (
                <NavigationButtons
                    prevDisabled={!groupList?._links.prev?.href}
                    nextDisabled={!groupList._links.next?.href}
                    onPrevClick={handlePrev}
                    onNextClick={handleNext}
                />
            ) : null}
        </>
    );
};

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    maxWidth: 'none',
});

const StyledBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const StyledDiv = styled('div')({
    flex: '0 0 calc(40% - 5px)'
})

const useStyles = makeStyles()({
    tableKey: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '20rem',
        width: '14rem',
        fontWeight: 'bold',
        fontSize: '0.875rem',
        color: '#333',
        marginRight: '1rem',
        whiteSpace: 'nowrap',
    },
    tableValue: {
        flexGrow: 1,
        fontSize: '0.875rem',
        wordBreak: 'break-word',
        color: '#555',
        width: '17rem',
        maxWidth: '20rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
            overflow: 'visible',
            whiteSpace: 'normal',
        },
    },
});
