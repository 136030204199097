import React, { HTMLAttributes } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalize } from '../../helpers';
import { Verb } from '../../repository/models/Policy';

const verbValues: Verb[] = ['read', 'create', 'update', 'delete'];

export type PolicyVerbsProps = {
    verbs: Verb[];
    onChange: (verbs: Verb[]) => void;
};

export const PolicyVerbs = ({
    verbs,
    onChange,
}: PolicyVerbsProps & Omit<HTMLAttributes<HTMLDivElement>, keyof PolicyVerbsProps>) => {
    const handleChange = (value: boolean, verb: Verb) => {
        if (value) {
            onChange(verbs.concat([verb]));
            return;
        }
        onChange(verbs.filter((v) => v !== verb));
    };

    return (
        <Grid container gap={1}>
            {verbValues.map((verb) => (
                <PolicyVerb key={verb} verb={verb} checked={verbs.includes(verb)} onChange={handleChange} />
            ))}
        </Grid>
    );
};

type PolicyVerbProps = {
    verb: Verb;
    checked: boolean;
    onChange: (checked: boolean, verb: Verb) => void;
};
const PolicyVerb = ({ verb, checked, onChange }: PolicyVerbProps) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    name={verb}
                    checked={checked}
                    color="primary"
                    onChange={(e) => onChange(e.target.checked, verb)}
                />
            }
            label={
                <Typography component="span" noWrap>
                    {capitalize(verb)}
                </Typography>
            }
        />
    );
};
