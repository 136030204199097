import { Box, Button, Typography, styled } from '@mui/material';
import { Alert } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';

import { RELEASE_CREATE } from '../../routes';
import { AppState } from '../../../store';
import { selectCurrentOrganization } from '../../account/accountSlice';
import { selectCurrentProject } from '../../project/projectSlice';
import { selectCurrentBlueprint } from '../blueprintSlice';
import { Blueprint } from '../../../repository/models/Blueprint';
import { Organization } from '../../../repository/models/Organization';
import { Project } from '../../../repository/models/Project';
import { useGetBlueprintStagedHistoryQuery } from '../api';
import { RequestStateHandler } from '../../../ui/RequestStateHandler';

interface ChangesReminderProps {
    currentBlueprint: Blueprint | null;
    currentOrganization: Organization | null;
    currentProject: Project | null;
}

const ChangesReminder = (props: ChangesReminderProps) => {
    const { data: history, isLoading, error } = useGetBlueprintStagedHistoryQuery(props.currentBlueprint ?? skipToken);

    if (isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />;
    }

    const operations = history?.flatMap((cs) => cs.operations);

    if (!operations || !operations?.length || props.currentProject === null) {
        return <></>;
    }

    return (
        <Box marginBottom={2}>
            <StyledAlert severity="info">
                <Typography>Some changes have not yet been released.</Typography>
                <Box position="absolute" right={16} top={8}>
                    <Button
                        color="secondary"
                        component={RouterLink}
                        variant="outlined"
                        to={RELEASE_CREATE.generate({
                            org: props.currentOrganization!.name,
                            project: props.currentProject!.name,
                            blueprint: props.currentBlueprint!.name,
                        })}
                    >
                        Create Release
                    </Button>
                </Box>
            </StyledAlert>
        </Box>
    );
};

export default connect((state: AppState) => ({
    currentOrganization: selectCurrentOrganization(state),
    currentProject: selectCurrentProject(state),
    currentBlueprint: selectCurrentBlueprint(state),
}))(ChangesReminder);

const StyledAlert = styled(Alert)({
    position: 'relative',
    '& .MuiAlert-icon': {
        alignSelf: 'center',
    }
});
