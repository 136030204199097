import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ForwardedRef, ReactNode } from 'react';

export interface CellProps {
    children?: ReactNode;
    isLarge?: boolean;
    width?: number;
    isContentCentered?: boolean;
    isColumn?: boolean;
}

export const Cell = React.forwardRef(
    ({ children, isLarge, width, isContentCentered, isColumn }: CellProps, ref: ForwardedRef<any>) => {
        const { classes, cx } = useStyles(width)();
        const cellClasses = cx(classes.cell, {
            [classes.large]: isLarge,
            [classes.flexible]: !width,
            [classes.notFlexible]: !!width,
            [classes.center]: isContentCentered,
            [classes.column]: isColumn,
        });

        return (
            <div ref={ref} style={{ fontSize: '0.875rem' }} className={cellClasses}>
                {children}
            </div>
        );
    },
);

const useStyles = (size?: number) =>
    makeStyles()((_theme: Theme) =>
        ({
            cell: {
                display: 'flex',
                alignItems: 'center',
                padding: '6px 16px',
                wordBreak: 'break-word',
            },
            large: {
                padding: '16px',
            },
            flexible: {
                flex: '1 0',
            },
            notFlexible: {
                minWidth: size + '%',
                maxWidth: size + '%',
            },
            center: {
                justifyContent: 'center',
            },
            column: {
                flexDirection: 'column',
            },
        }),
    );
