import React, { ForwardedRef } from 'react';
import { InputBase, Theme, alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type TextInputProps = {
    autoFocus?: boolean;
    fullWidth?: boolean;
    placeholder?: string;
    handleOnChange?: (value: string) => void;
    handleOnKeyDown?: (key: string) => void;
    inputRef?: React.Ref<HTMLInputElement>;
    value?: string;
    short?: boolean;
    className?: string;
    disabled?: boolean;
    id?: string;
    type?: string;
    onBlur?: () => void;
    error?: boolean;
    spellCheck?: boolean;
    style?: React.CSSProperties;
};

export default React.forwardRef(function TextInput(
    { spellCheck = false, ...props }: TextInputProps,
    ref: ForwardedRef<any>,
) {
    const { classes } = useStyles();

    return (
        <InputBase
            ref={ref}
            className={`${classes.input} ${props.short ? classes.short : ''} ${props.className ? props.className : ''}`}
            placeholder={props.placeholder}
            onChange={(e) => {
                props.handleOnChange && props.handleOnChange(e.target.value);
            }}
            onKeyDown={(e) => {
                props.handleOnKeyDown && props.handleOnKeyDown(e.key);
            }}
            value={props.value}
            autoFocus={props.autoFocus}
            fullWidth={props.fullWidth}
            disabled={props.disabled}
            id={props.id}
            type={props.type}
            onBlur={props.onBlur}
            error={props.error}
            style={props.style}
            inputProps={{
                spellCheck: spellCheck,
            }}
            inputRef={props.inputRef}
        />
    );
});

const useStyles = makeStyles()((theme: Theme) =>
    ({
        input: {
            padding: '0 .6em',
            border: '1px solid',
            borderColor: theme.palette.grey[300],
            borderRadius: '.25em',
            '&.Mui-focused': {
                borderColor: theme.palette.primary.main,
                boxShadow: alpha(theme.palette.primary.main, 0.5) + ' 0 0 0px 3px',
            },
            '&.Mui-error': {
                borderColor: theme.palette.error.main,
            },
            '&.Mui-error.Mui-focused': {
                borderColor: theme.palette.error.main,
                boxShadow: alpha(theme.palette.error.main, 0.5) + ' 0 0 0px 3px',
            },
            '&:hover:not(.Mui-error):not(.Mui-disabled)': {
                borderColor: theme.palette.primary.main,
                transition: 'border-color 200ms ease-out',
            },
        },
        short: {
            maxWidth: '20ch',
        },
    }),
);
