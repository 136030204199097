import { skipToken } from '@reduxjs/toolkit/query/react';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { Cell } from '../../../ui/table/Cell';
import { Row } from '../../../ui/table/Row';
import { TableHeader } from '../../../ui/typography/TableHeader';
import { IAM_REALM_CREATE_USER, IAM_REALM_USER } from '../../routes';
import { Organization } from '../../../repository/models/Organization';
import { useGetRealmUsersQuery } from '../api';
import { IamRealm } from '../../../repository/models/IamRealm';
import { IamTable, StyledIamNavLink } from '../../../ui/table/IamTable';
import { TypedLink, resolveTemplate } from '../../../hal';
import { HighlightedComponent, iamCreateUserLocation } from '../../tour';
import { useVisitedTourLocation } from '../../tour/hooks';
import { NavigationButtons } from '../components/NavigationButtons';
import { IamUserList } from '../../../repository/models/IamUser';
import { RemoveCircleOutline } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

interface IamRealmUsersProps {
    org: Organization | null;
    realm?: IamRealm;
}

export const IamRealmUsers = ({ org, realm }: IamRealmUsersProps) => {
    const [link, setLink] = useState<TypedLink<IamUserList>>();
    const { data: userList, isLoading, error } = useGetRealmUsersQuery(link ?? realm?._links?.users?.href ?? skipToken);

    const users = userList?._embedded?.users ?? [];

    const userTemplate = useMemo(() => {
        if (!!userList) {
            return resolveTemplate(userList!, 'default');
        }
        return null;
    }, [userList]);

    useVisitedTourLocation(iamCreateUserLocation, () => users.length > 0, [users]);

    const handleNext = () => {
        if (!!userList?._links?.next?.href) {
            setLink(userList?._links?.next.href);
        }
    };

    const handlePrev = () => {
        if (!!userList?._links?.prev?.href) {
            setLink(userList?._links?.prev.href);
        }
    };

    return (
        <>
            {!!userTemplate && (
                <Box marginBottom={2} display="flex" justifyContent="flex-end">
                    <HighlightedComponent location={iamCreateUserLocation}>
                        <Button
                            color="primary"
                            variant="contained"
                            component={Link}
                            to={IAM_REALM_CREATE_USER.generate({
                                org: org?.name!,
                                realm: realm?.id!,
                            })}
                        >
                            Create user
                        </Button>
                    </HighlightedComponent>
                </Box>
            )}

            <IamTable
                isLoading={isLoading || !org}
                error={error}
                noRecords={users.length === 0}
                noRecordsText="No users."
                headers={
                    <>
                        <TableHeader>Username</TableHeader>
                        <TableHeader>Email</TableHeader>
                        <TableHeader width={15}>First Name</TableHeader>
                        <TableHeader width={15}>Last Name</TableHeader>
                    </>
                }
            >
                {(users ?? []).map((user) => (
                    <Row key={user._links.self.href} borders="horizontal">
                        <Cell>
                            <StyledIamNavLink
                                to={IAM_REALM_USER.generate({
                                    org: org!.name,
                                    realm: realm?.id ?? '',
                                    user: user.userId,
                                })}
                            >
                                {user.username}
                            </StyledIamNavLink>

                            {!user.enabled ? (
                                <Box display="flex" marginLeft={1} height="20px" alignItems="center">
                                    <Tooltip title="User is deactivated">
                                        <RemoveCircleOutline color="error" fontSize="small" />
                                    </Tooltip>
                                </Box>
                            ) : null}
                        </Cell>
                        <Cell>{user.email ?? '-'}</Cell>
                        <Cell width={15}>{user.firstName ?? '-'}</Cell>
                        <Cell width={15}>{user.lastName ?? '-'}</Cell>
                    </Row>
                ))}

                {!!userList?._links.next || !!userList?._links.prev ? (
                    <NavigationButtons
                        prevDisabled={!userList?._links.prev?.href}
                        nextDisabled={!userList._links.next?.href}
                        onPrevClick={handlePrev}
                        onNextClick={handleNext}
                    />
                ) : null}
            </IamTable>
        </>
    );
};
