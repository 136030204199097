import { theme } from '../../../theme';

export const largeLetterSize = 8.2;
export const smallLetterSize = 5.87;
export const boxPaddings = parseFloat(theme.spacing(2));
export const iconsBox = parseFloat(theme.spacing(6));
export const minWidth = parseFloat(theme.spacing(30));
export const bordersHeight = 2;
export const relationMiddle = parseFloat(theme.spacing(2));
export const relationMarginBottom = parseFloat(theme.spacing(1));
export const attrItemHeight = parseFloat(theme.spacing(3));
export const relItemHeight = parseFloat(theme.spacing(4));
export const attrMarginBottom = parseFloat(theme.spacing(1));
export const relMarginBottom = parseFloat(theme.spacing(1));
export const headerHeight = parseFloat(theme.spacing(4));
export const attrBodySpacing = parseFloat(theme.spacing(2));