import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '16px',
    backgroundColor: '#fff',
    transition: 'background-color 200ms ease-in',
    borderRadius: '0.25rem',
    '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
        transition: 'color 200ms ease-in',
    },
    '&.MuiListItemButton-root': {
        borderRadius: '0.25rem',
    },
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.light,
        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.dark,
            transition: 'color 200ms ease-out',
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    '&:hover': {
        backgroundColor: theme.palette.grey[100],
        transition: 'background-color 200ms ease-out',
        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.dark,
            transition: 'color 200ms ease-out',
        },
    },
}));
