import React from 'react';
import { useReactFlow, getRectOfNodes, getTransformForBounds } from 'reactflow';
import { toPng } from 'html-to-image';
import { IconButton } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

function downloadImage(dataUrl: string) {
    const a = document.createElement('a');

    a.setAttribute('download', 'schema.png');
    a.setAttribute('href', dataUrl);
    a.click();
}

const imageWidth = 3000;
const imageHeight = 1500;

export function DownloadButton() {
    const { getNodes } = useReactFlow();

    const onClick = () => {
        const nodesBounds = getRectOfNodes(getNodes());
        const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

        const element = document.querySelector('.react-flow__viewport') as HTMLElement;

        if (element) {
            toPng(element, {
                backgroundColor: 'white',
                width: imageWidth,
                height: imageHeight,
                style: {
                    width: imageWidth + '',
                    height: imageHeight + '',
                    transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
                },
            }).then(downloadImage);
        }
    };

    return (
        <Tooltip title="Download image">
            <IconButton onClick={onClick}>
                <GetApp />
            </IconButton>
        </Tooltip>
    );
}
