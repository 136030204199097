import { styled } from '@mui/material';
import React, { ReactNode } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import WebIcon from '@mui/icons-material/Web';
import { Box } from '@mui/material';

import { Application } from '../../../../../../repository/models/Application';
import { HighlightedComponent, applicationVisitLocation } from '../../../../../tour';
import { CopyText } from '../../../../../../ui/CopyText';
import OASIcon from '../../../../../../ui/icons/OASIcon';
import { OverviewRow, OverviewSubItem } from '../../../../../../ui/Overview';
import { RequestStateHandler } from '../../../../../../ui/RequestStateHandler';
import { useGetApplicationConfigQuery, useGetWebappsQuery } from '../../../../api';

interface OverviewLinksRowProps {
    application: Application;
    isDeployed: boolean;
}

export const OverviewLinksRow = ({ application, isDeployed }: OverviewLinksRowProps) => {
    const { data: config, isLoading: isConfigLoading, error: configError } = useGetApplicationConfigQuery(application);
    const { data: webapps, isLoading: isWebappsLoading, error: webappsError } = useGetWebappsQuery(application);

    const baseLink = !!config?.domainNames[0] ? `https://${config.domainNames[0]}` : null;

    const hrefToApp = !!baseLink ? `${baseLink}/webjars/swagger-ui/index.html` : null;
    const explorerLink = !!baseLink ? `${baseLink}/` : null;
    const webappLinks =
        (webapps?.length ?? 0) > 0 ? webapps?.flatMap((x) => x.domains.map((d) => 'https://' + d)) : undefined;

    return (
        <OverviewRow>
            <Box flexGrow={1}>
                {config !== null && isDeployed ? (
                    <>
                        <OverviewSubItem label="Navigator">
                            {!!webappLinks?.[0] ? (
                                <AppLink url={webappLinks[0]} text="Visit app" icon={<WebIcon fontSize="small" />} />
                            ) : null}
                        </OverviewSubItem>

                        <OverviewSubItem label="Interactive OpenAPI">
                            <HighlightedComponent location={applicationVisitLocation} markVisitedOnClick>
                                {/* This span is here so that the HighlightedComponent always has an element for it to ref (it can't ref a fragment) */}
                                <span>
                                    <AppLink url={hrefToApp} text="Visit app" icon={<OASIcon fontSize="small" />} />
                                </span>
                            </HighlightedComponent>
                        </OverviewSubItem>

                        <OverviewSubItem label="HAL Explorer">
                            <AppLink url={explorerLink} text="Visit app" icon={<LaunchIcon fontSize="inherit" />} />
                        </OverviewSubItem>

                        <OverviewSubItem label="API URL">
                            {!!baseLink ? (
                                <>
                                    <CopyText text={baseLink} />
                                </>
                            ) : (
                                <>&mdash;</>
                            )}
                        </OverviewSubItem>
                    </>
                ) : !!configError || isConfigLoading ? (
                    <RequestStateHandler error={configError} isLoading={isConfigLoading} />
                ) : !!webappsError || isWebappsLoading ? (
                    <RequestStateHandler error={webappsError} isLoading={isWebappsLoading} />
                ) : null}
                <OverviewSubItem label="ID">
                    <CopyText text={application.id} />
                </OverviewSubItem>
            </Box>
        </OverviewRow>
    );
};

const StyledLink = styled('a')(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    width: 'max-content',
    marginRight: theme.spacing(4),
    '&:hover': {
        color: theme.palette.primary.dark,
        textDecoration: 'underline',
    },
}));

const AppLink = ({ url, icon, text }: { url: string | null; icon: ReactNode; text: string }) => {
    return url === null ? (
        <>&mdash;</>
    ) : (
        <>
            {icon}
            <StyledLink rel="noopener noreferrer" target="_blank" href={url}>
                {text}
            </StyledLink>
        </>
    );
};
