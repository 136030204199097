import { Box, Button, Paper, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import React from 'react';
import { connect } from 'react-redux';

import { useGetApplicationsForProjectQuery } from '../api';
import { selectCurrentBlueprint } from '../../blueprint/blueprintSlice';
import { selectCurrentProject } from '../../project/projectSlice';
import AutoBreadcrumbs from '../../routes/AutoBreadcrumbs';
import InstancesRow from './components/InstancesRow';
import { Blueprint } from '../../../repository/models/Blueprint';
import { Project } from '../../../repository/models/Project';
import { AppState } from '../../../store';
import CreateApplicationPopover from '../CreateApplicationPopover';
import { Row } from '../../../ui/table/Row';
import { Application } from '../../../../src/repository/models/Application';
import { TableHeader } from '../../../ui/typography/TableHeader';
import { applicationCreateLocation, HighlightedComponent } from '../../tour';
import { RequestStateHandler } from '../../../ui/RequestStateHandler';
import useInterval from '../../interval';
import { useVisitedTourLocation } from '../../tour/hooks';
import { Alert } from '@mui/material';
import useQuery from '../../useQuery';

const titles = ['Application', 'Status', 'Created', 'Updated'];

type InstancesProps = {
    project: Project | null;
    blueprint: Blueprint | null;
};

function Instances({ project }: InstancesProps) {
    const [showCreateApplication, setShowCreateApplication] = React.useState<boolean>(false);

    const {
        data: applicationsData,
        isLoading,
        error,
        refetch,
    } = useGetApplicationsForProjectQuery(project ?? skipToken);

    useInterval(() => {
        if ((applicationsData ?? []).some((app) => app.state.progressing)) {
            refetch();
        }
    }, 3000);

    return (
        <>
            <AutoBreadcrumbs>Applications</AutoBreadcrumbs>

            <Box mb={2}>
                {(() => {
                    if (applicationsData === undefined) {
                        return <RequestStateHandler isLoading={isLoading} error={error} />;
                    } else if (applicationsData.length === 0) {
                        return (
                            <>
                                <DeployingAlert>
                                    You must create an application before you can deploy to it.
                                </DeployingAlert>
                                <Paper variant="outlined">
                                    <Typography
                                        align="center"
                                        style={{
                                            paddingTop: '.5rem',
                                            paddingBottom: '.5rem',
                                        }}
                                    >
                                        No applications yet.
                                    </Typography>
                                </Paper>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <DeployingAlert>Choose which application to deploy to.</DeployingAlert>
                                <InstancesTable applicationsData={applicationsData} project={project} />
                            </>
                        );
                    }
                })()}
            </Box>

            <HighlightedComponent location={applicationCreateLocation}>
                <Button color="primary" variant="contained" onClick={() => setShowCreateApplication(true)}>
                    New Application
                </Button>
            </HighlightedComponent>

            <CreateApplicationPopover
                show={showCreateApplication}
                handleClose={() => setShowCreateApplication(false)}
                handleCreateApplication={() => setShowCreateApplication(false)}
            />
        </>
    );
}

export default connect((state: AppState) => ({
    blueprint: selectCurrentBlueprint(state),
    project: selectCurrentProject(state),
}))(Instances);

type InstancesTableProps = {
    project: Project | null;
    applicationsData: readonly Application[];
};

const InstancesTable = ({ applicationsData, project }: InstancesTableProps) => {
    useVisitedTourLocation(applicationCreateLocation, () => applicationsData.length > 0);

    // 0 -> takes up remaing space
    const columnWidths = [0, 25, 15, 15];

    return (
        <>
            <Row>
                {titles.map((item, index) => {
                    return (
                        <TableHeader key={item} width={columnWidths[index]}>
                            {item}
                        </TableHeader>
                    );
                })}
            </Row>

            {applicationsData.map((row, index) => (
                <InstancesRow
                    key={index}
                    application={row}
                    project={project}
                    isNotFirstDescendant={index !== 0}
                    columnWidths={columnWidths}
                />
            ))}
        </>
    );
};

const DeployingAlert = (props: { children: React.ReactNode }) =>
    useQuery().get('deploying') ? (
        <Alert severity="info" style={{ marginBottom: '1rem' }}>
            {props.children}
        </Alert>
    ) : null;
