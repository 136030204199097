import React, { ComponentType, ReactNode, useState } from 'react';
import { AllowedValuesAttributeConstraint, Attribute, AttributeConstraint } from '../../repository/models/Entity';
import {
    useDeleteConstraintMutation,
    useEnableRequiredConstraintMutation,
    useEnableUniqueConstraintMutation,
    useSetAllowedValuesConstraintMutation,
} from './api';
import { HalForm, resolveTemplate, resolveTemplateRequired, WithoutHal } from '../../hal';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Delete, Edit, Fingerprint as FingerPrintIcon, List as ListIcon } from '@mui/icons-material';
import { RequireIcon } from '../../ui/icons/RequireIcon';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import BusyButton from '../../BusyButton';
import { TextInputList } from '../../ui/input/TextInputList';
import { ServerErrorMessage } from '../../ui/ServerErrorMessage';

type ActiveConstraintFormComponent = ComponentType<{
    attribute: Attribute;
    onClose: () => void;
}>;

interface AttributeConstraintsModelProps {
    attribute: Attribute;
}

export default function AttributeConstraintsModel(props: AttributeConstraintsModelProps) {
    const [enableRequiredConstraint, { error: enableRequiredError, reset: enableRequiredReset }] =
        useEnableRequiredConstraintMutation();
    const [enableUniqueConstraint, { error: enableUniqueError, reset: enableUniqueReset }] =
        useEnableUniqueConstraintMutation();
    const [deleteConstraint, { error: deleteConstraintError, reset: deleteConstraintReset }] =
        useDeleteConstraintMutation();

    const [activeConstraintForm, setActiveConstraintForm] = useState<null | {
        title: string;
        component: ActiveConstraintFormComponent;
    }>(null);

    return (
        <Box marginBottom={3}>
            <ServerErrorMessage error={enableRequiredError ?? enableUniqueError ?? deleteConstraintError} />
            {props.attribute._embedded?.constraints?.map((constraint) => (
                <GenericConstraint
                    key={constraint._links.self.href}
                    attribute={props.attribute}
                    constraint={constraint}
                    onEdit={(title, component) => setActiveConstraintForm({ title, component })}
                    onDelete={(constraint) => {
                        enableRequiredReset();
                        enableUniqueReset();
                        deleteConstraint(constraint);
                    }}
                />
            )) ?? null}
            <CreateConstraintButton
                attribute={props.attribute}
                onEnableRequired={() => {
                    deleteConstraintReset();
                    enableRequiredConstraint({
                        attribute: props.attribute,
                    });
                }}
                onEnableUnique={() => {
                    deleteConstraintReset();
                    enableUniqueConstraint({
                        attribute: props.attribute,
                    });
                }}
                onEnableConstraint={(title, component) => setActiveConstraintForm({ title, component })}
            />
            <Dialog open={activeConstraintForm != null} onClose={() => setActiveConstraintForm(null)}>
                <DialogTitle>{activeConstraintForm?.title}</DialogTitle>
                {activeConstraintForm && (
                    <activeConstraintForm.component
                        attribute={props.attribute}
                        onClose={() => setActiveConstraintForm(null)}
                    />
                )}
            </Dialog>
        </Box>
    );
}

interface SimpleConstraintProps {
    attribute: Attribute;
    title: ReactNode;
    onEdit?: () => void;
    onDelete?: () => void;
}

function SimpleConstraint(props: SimpleConstraintProps) {
    const { classes } = useSimpleConstraintStyles();
    return (
        <Typography className={classes.typography}>
            {props.title}
            {props.onEdit && (
                <IconButton size="small" onClick={props.onEdit}>
                    <Edit fontSize="inherit" />
                </IconButton>
            )}
            {props.onDelete && (
                <IconButton size="small" onClick={props.onDelete}>
                    <Delete fontSize="inherit" />
                </IconButton>
            )}
        </Typography>
    );
}

const useSimpleConstraintStyles = makeStyles()(() => ({
    typography: {
        display: 'flex',
        alignItems: 'center',
    },
}));

interface GenericConstraintProps<C = AttributeConstraint> {
    attribute: Attribute;
    constraint: C;
    onEdit: (title: string, component: ActiveConstraintFormComponent) => void;
    onDelete: (constraint: C) => void;
}

function GenericConstraint({ constraint, ...props }: GenericConstraintProps) {
    switch (constraint.type) {
        case 'allowed-values':
            return <AllowedValuesConstraint {...props} constraint={constraint as AllowedValuesAttributeConstraint} />;
        case 'required':
            return (
                <SimpleConstraint
                    attribute={props.attribute}
                    title={
                        <>
                            <RequireIcon fontSize="inherit" />
                            &nbsp;Value is required
                        </>
                    }
                    onDelete={() => props.onDelete(constraint)}
                />
            );
        case 'unique':
            return (
                <SimpleConstraint
                    attribute={props.attribute}
                    title={
                        <>
                            <FingerPrintIcon fontSize="inherit" />
                            &nbsp;Value is unique
                        </>
                    }
                    onDelete={() => props.onDelete(constraint)}
                />
            );
        default:
            return <Typography color="error">Unknown constraint "{constraint.type}"</Typography>;
    }
}

function AllowedValuesConstraint(props: GenericConstraintProps<AllowedValuesAttributeConstraint>) {
    const allowedValuesForm = resolveTemplateRequired(props.constraint, 'default');
    return (
        <SimpleConstraint
            attribute={props.attribute}
            title={
                <>
                    <Box marginRight={2}>
                        <ListIcon fontSize="inherit" />
                    </Box>
                    Allowed values: {(props.constraint as AllowedValuesAttributeConstraint).values.join(', ')}
                </>
            }
            onEdit={
                allowedValuesForm
                    ? () =>
                          props.onEdit('Update allowed values', (p) => (
                              <AllowedValuesConstraintForm
                                  constraint={props.constraint as AllowedValuesAttributeConstraint}
                                  form={allowedValuesForm}
                                  {...p}
                              />
                          ))
                    : undefined
            }
            onDelete={() => props.onDelete(props.constraint)}
        />
    );
}

interface CreateConstraintButtonProps {
    attribute: Attribute;
    onEnableRequired: () => void;
    onEnableUnique: () => void;
    onEnableConstraint: (title: string, formComponent: ActiveConstraintFormComponent) => void;
}

function CreateConstraintButton(props: CreateConstraintButtonProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = !!anchorEl;

    const canAddRequired = resolveTemplate(props.attribute, 'enable-required') != null;
    const canAddUnique = resolveTemplate(props.attribute, 'enable-unique') != null;

    const canAddAllowedValues = !props.attribute._embedded?.constraints?.find((c) => c.type === 'allowed-values')
        ? resolveTemplate(props.attribute, 'set-allowed-values')
        : null;

    return (
        <Box marginTop={2}>
            <Button
                disabled={[canAddRequired, canAddUnique, canAddAllowedValues].every((enabled) => !enabled)}
                variant="contained"
                color="primary"
                onClick={(ev) => setAnchorEl(ev.currentTarget)}
                endIcon={<KeyboardArrowDown />}
            >
                Add constraint
            </Button>
            <Menu open={menuOpen} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                <MenuItem
                    disabled={!canAddRequired}
                    onClick={() => {
                        props.onEnableRequired();
                        setAnchorEl(null);
                    }}
                >
                    <RequireIcon fontSize="inherit" />
                    &nbsp;Value is required
                </MenuItem>
                <MenuItem
                    disabled={!canAddUnique}
                    onClick={() => {
                        props.onEnableUnique();
                        setAnchorEl(null);
                    }}
                >
                    <FingerPrintIcon fontSize="inherit" />
                    &nbsp; Must be unique
                </MenuItem>
                <MenuItem
                    disabled={!canAddAllowedValues}
                    onClick={() => {
                        props.onEnableConstraint('Configure allowed values', (props) => (
                            <AllowedValuesConstraintForm
                                constraint={{
                                    type: 'allowed-values',
                                    values: [''],
                                }}
                                form={canAddAllowedValues!}
                                {...props}
                            />
                        ));
                        setAnchorEl(null);
                    }}
                >
                    <ListIcon fontSize="inherit" />
                    &nbsp;Set allowed values
                </MenuItem>
            </Menu>
        </Box>
    );
}

interface AllowedValuesConstraintFormProps {
    attribute: Attribute;
    constraint: WithoutHal<AllowedValuesAttributeConstraint>;
    form: HalForm<AllowedValuesAttributeConstraint>;
    onClose: () => void;
}

function AllowedValuesConstraintForm(props: AllowedValuesConstraintFormProps) {
    const [setAllowedValuesConstraint, { isLoading, error }] = useSetAllowedValuesConstraintMutation();
    const [allowedValues, setAllowedValues] = useState<readonly string[]>(props.constraint.values);
    return (
        <>
            <DialogContent>
                <ServerErrorMessage error={error} />
                <TextInputList kindTitle="allowed value" list={allowedValues} onChange={setAllowedValues} />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" disabled={isLoading} autoFocus onClick={props.onClose}>
                    Cancel
                </Button>
                <BusyButton
                    busy={isLoading}
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                        await setAllowedValuesConstraint({
                            attribute: props.attribute,
                            form: props.form,
                            constraint: {
                                type: 'allowed-values',
                                values: allowedValues.filter((v) => !!v),
                            },
                        }).unwrap();
                        props.onClose();
                    }}
                >
                    Save
                </BusyButton>
            </DialogActions>
        </>
    );
}
