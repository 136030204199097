import React from 'react';
import { Link as MuiLink, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack } from '@mui/icons-material';
import { To } from 'react-router';
import { Link as NavLink } from 'react-router-dom';

type BackLinkProps = {
    to: To;
    children: React.ReactNode;
};

const useStyles = makeStyles()((_theme: Theme) => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: '1rem',
    },
}));

export default function BackLink({ to, children }: BackLinkProps) {
    const { classes } = useStyles();
    return (
        <MuiLink underline="hover" className={classes.root} component={NavLink} to={to}>
            <ArrowBack style={{ marginRight: '5px' }} fontSize="inherit" />
            {children}
        </MuiLink>
    );
}
