import React, { useEffect } from 'react';
import { Attribute, AttributeSearchOption } from '../../repository/models/Entity';
import { useDisableSearchOptionMutation, useEnableExactSearchOptionMutation, useEnablePrefixSearchOptionMutation } from './api';
import { resolveTemplate } from '../../hal';
import {
    CircularProgress,
    Typography,
} from '@mui/material';
import { Check, Error as ErrorIcon } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { CheckboxIconLabel } from '../../ui/CheckboxIconLabel';
import RemapPalette from '../../ui/theme/RemapPalette';
import { SerializedError } from '@reduxjs/toolkit';


interface AttributeSearchOptionsModelProps {
    attribute: Attribute;
}

export default function AttributeSearchOptionsModel(props: AttributeSearchOptionsModelProps) {
    const exactSearchOption = props.attribute._embedded?.['search-options']?.find((option) => option.type === 'exact');
    const prefixSearchOption = props.attribute._embedded?.['search-options']?.find((option) => option.type === 'prefix');

    if (!props.attribute._embedded?.['search-options']?.length && !['enable-exact-search', 'enable-prefix-search'].some((name) => resolveTemplate(props.attribute, name))) {
        // No search options enabled and no templates found to enable
        return <Typography color='textSecondary'>This attribute is not searchable.</Typography>
    }

    return (
        <>
            <SearchOptionCheckBox
                        enable={useEnableExactSearchOptionMutation}
                        attribute={props.attribute}
                        enableTemplateName='enable-exact-search'
                        option={exactSearchOption}
                        label='Search values exactly'
                    />
            <SearchOptionCheckBox
                        enable={useEnablePrefixSearchOptionMutation}
                        attribute={props.attribute}
                        enableTemplateName='enable-prefix-search'
                        option={prefixSearchOption}
                        label="Search values starting with given prefix"
                    />
        </>
    );
}

type MutationData<P> = readonly [(payload: P) => unknown, {
    isLoading: boolean,
    error?: SerializedError | undefined,
    isSuccess: boolean,
    reset: () => void
}];

interface SearchOptionCheckBoxProps {
    enable: () => MutationData<{attribute: Attribute}>,
    enableTemplateName: keyof Attribute['_templates'],
    attribute: Attribute,
    option: AttributeSearchOption | undefined,
    label: string,
}

function SearchOptionCheckBox(props: SearchOptionCheckBoxProps) {
    const [enable, {
        isLoading: isEnableLoading,
        error: enableError,
        isSuccess: isEnableSuccess,
        reset: resetEnable
    }] = props.enable();
    const [disable, {
        isLoading: isDisableLoading,
        error: disableError,
        isSuccess: isDisableSuccess,
        reset: resetDisable
    }] = useDisableSearchOptionMutation();

    const canEnable = resolveTemplate(props.attribute, props.enableTemplateName) != null;
    const canDisable = props.option && resolveTemplate(props.option, 'delete');

    const isLoading = isEnableLoading || isDisableLoading;
    const error = enableError ?? disableError;
    const isSuccess = isEnableSuccess || isDisableSuccess;
    const isEnabled = !!props.option;

    useEffect(() => {
            if (isSuccess) {
                const timer = setTimeout(() => {
                    if (isSuccess) {
                        resetEnable();
                        resetDisable();
                    }
                }, 3000);
                return () => clearTimeout(timer);
            }
            return () => {};
        }, [isSuccess, resetEnable, resetDisable]);

    if (!props.option && !canEnable) {
        // Option does not exist and can not be enabled
        return null;
    }

    return <div><CheckboxIconLabel
    checked={isEnabled}
    disabled={isEnabled && !canDisable}
    onChange={(option) => {
        if (option) {
            resetDisable();
            enable({ attribute: props.attribute});
        } else if (props.option) {
            resetEnable();
            disable(props.option);
        }
    }}>
        <SearchIcon fontSize="inherit" color="action" />
        <Typography component="span" noWrap>
            {props.label}
        </Typography>
        {isLoading && (
            <>
                &emsp;
                <CircularProgress />
            </>
        )}
        {error && (
            <RemapPalette from="error" to="primary">
                &emsp;
                <ErrorIcon fontSize="inherit" color="primary" />
                <Typography component="span" noWrap color="primary">
                    {' '}
                    Error: {error.message}
                </Typography>
            </RemapPalette>
        )}
        {isSuccess && (
            <RemapPalette from="success" to="primary">
                &emsp;
                <Check fontSize="inherit" color="primary" />
                <Typography component="span" noWrap color="primary">
                    {' '}
                    Saved
                </Typography>
            </RemapPalette>
        )}
    </CheckboxIconLabel></div>
}
