import React from 'react';
import { Alert } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { OverviewRow } from '../../../../ui/Overview';
import { IamGroupAttributes } from '../../../../repository/models/IamGroup';

interface AttributesTableProps {
    attributes: IamGroupAttributes;
}

export const AttributesTable = ({ attributes }: AttributesTableProps) => {
    const list = Object.entries(attributes);

    if (!list.length) {
        return <Alert severity="info">There are no attributes!</Alert>;
    }

    return (
        <OverviewRow>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(([attrName, attrValue]) => (
                        <TableRow key={attrName}>
                            <TableCell>{attrName}</TableCell>
                            <TableCell>
                                {Array.isArray(attrValue) ? (
                                    <AttributeArrayValue value={attrValue} />
                                ) : (
                                    <AttributeValue
                                        value={attrValue.toString()}
                                        type={typeof attrValue as 'string' | 'number' | 'boolean'}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </OverviewRow>
    );
};

type AttributeValueProps = {
    value: string;
    type: 'string' | 'number' | 'boolean';
};

const AttributeValue = ({ value, type }: AttributeValueProps) => {
    let color =
        type === 'string'
            ? '#117733' //green
            : type === 'boolean'
              ? '#332288' //blue
              : type === 'number'
                ? '#aa4499' //purple?
                : 'black';
    let style = 'normal';
    if (type === 'string' && value === '') {
        style = 'italic';
        value = '(empty string)';
    } else if (type === 'string') {
        value = JSON.stringify(value);
    }

    return <span style={{ color: color, fontStyle: style }}>{value}</span>;
};

type AttributeArrayValueProps = {
    value: (string | number | boolean)[];
};

const AttributeArrayValue = ({ value }: AttributeArrayValueProps) => {
    return value.map((item, index) => {
        return (
            <React.Fragment key={index}>
                {index !== 0 ? ', ' : ''}
                <AttributeValue value={item.toString()} type={typeof item as 'string' | 'number' | 'boolean'} />
            </React.Fragment>
        );
    });
};
