import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

interface PageTitleProps {
    children: ReactNode;
}

export const PageTitle = ({ children }: PageTitleProps) => {
    const { classes } = useStyles();
    return (
        <Typography className={classes.wrapper} component="h3">
            <span className={classes.text}>{children}</span>
        </Typography>
    );
};

const useStyles = makeStyles()(() =>
    ({
        wrapper: {
            marginBottom: '1rem',
        },
        text: {
            fontWeight: 500,
            fontSize: '1.5rem',
        },
    }),
);
