import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Theme,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { skipToken } from '@reduxjs/toolkit/query';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BusyButton from '../../BusyButton';
import { Organization } from '../../repository/models/Organization';
import { Project } from '../../repository/models/Project';
import { Zone } from '../../repository/models/Zone';
import { AppState } from '../../store';
import { selectCurrentOrganization } from '../account/accountSlice';
import { selectCurrentProject } from '../project/projectSlice';
import { useAddApplicationMutation, useGetZonesQuery } from './api';
import { INSTANCE_OVERVIEW } from '../routes';
import { ServerErrorMessage } from '../../ui/ServerErrorMessage';
import { resolveTemplateRequired } from '../../hal';
import ValidatedTextInput from '../../hal/forms/ValidatedTextInput';
import { ProgressIndicator } from '../../ui/ProgressIndicator';

type CreateApplicationPopoverProps = {
    organization: Organization | null;
    project: Project | null;
    show: boolean;
    handleClose: () => void;
    handleCreateApplication: () => void;
};

function CreateApplicationPopover({
    organization,
    project,
    show,
    handleClose,
    handleCreateApplication,
}: CreateApplicationPopoverProps) {
    const { classes, theme } = useStyles();
    const navigate = useNavigate();

    const [name, setName] = React.useState<string>('');

    const [addApplication, { isLoading, error }] = useAddApplicationMutation();

    const [zone, setZone] = useState<Zone | null>(null);

    const createApplication = async () => {
        if (project === null || zone === null) {
            return;
        }
        addApplication({
            project: project,
            applicationName: name,
            zone: zone,
        }).then((response) => {
            if ('data' in response) {
                // callback to parent
                handleCreateApplication();

                const url = INSTANCE_OVERVIEW.generate({
                    org: project.organization,
                    project: project.name,
                    application: name,
                });
                navigate(url);
            }
        });
    };

    const { data: zones, error: zonesError } = useGetZonesQuery(organization ?? skipToken);

    useEffect(() => {
        if (!!zones?.[0]) {
            setZone(zones[0]);
        }
    }, [zones, setZone]);

    useEffect(() => {
        return () => setName('');
    }, [setName]);

    if (!zone) {
        return <ProgressIndicator />;
    }

    const halProperty = resolveTemplateRequired(zone, 'createApplication').property('name');

    return (
        <Dialog open={show} onClose={handleClose}>
            <DialogTitle>Create Application</DialogTitle>
            <DialogContent
                className={classes.propertyEdit}
            >
                <ServerErrorMessage error={error || zonesError} />
                <div className={classes.formRow}>
                    <label htmlFor="app_name">Name:</label>
                    <ValidatedTextInput
                        id="app_name"
                        halProperty={halProperty}
                        displayName="Application name"
                        value={name}
                        autoFocus
                        handleOnChange={setName}
                    />
                </div>
                <div className={classes.formRow}>
                    <label htmlFor="zone">Deployment target:</label>
                    <Select
                        sx={{
                            overflow: 'clip',
                        }}
                        variant="standard"
                        value={zone?.name}
                        onChange={(e: SelectChangeEvent) =>
                            setZone(zones?.find((zone) => zone.name === (e.target.value as string))!)
                        }
                        id="zone"
                    >
                        {(zones ?? []).map((zone, index) => (
                            <MenuItem key={index} value={zone.name}>
                                {zone.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </DialogContent>
            <DialogActions sx={{ marginTop: theme.spacing(3) }}>
                <Button color="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <BusyButton
                    color="primary"
                    busy={isLoading}
                    variant="contained"
                    disableElevation
                    onClick={createApplication}
                    disabled={zone === null}
                >
                    Create
                </BusyButton>
            </DialogActions>
        </Dialog>
    );
}

export default connect((state: AppState) => ({
    organization: selectCurrentOrganization(state),
    project: selectCurrentProject(state),
}))(CreateApplicationPopover);

const useStyles = makeStyles()((theme: Theme) => ({
    propertyEdit: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        '& > * + *': {
            marginTop: theme.spacing(1),
        },
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        fontSize: 'medium',
        '& > label:first-of-type': {
            width: '9rem',
        },
        '& > div:nth-of-type(1)': {
            flex: '1 0',
        },
    },
}));
