import { Button, Tooltip } from '@mui/material';
import React, { useMemo, useState } from 'react';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import RelativeTime from '@yaireo/relative-time';
import { skipToken } from '@reduxjs/toolkit/query';

import { Project } from '../../../../../repository/models/Project';
import { useGetReleasesForProjectQuery } from '../../../../release/api';
import { Application } from '../../../../../repository/models/Application';
import { HighlightedComponent, applicationDeployLocation } from '../../../../tour';
import { Deployment } from '../../../../../repository/models/Deployment';
import { Zone } from '../../../../../repository/models/Zone';
import InstancePageLastReleaseIndicator from './components/InstancePageLastReleaseIndicator';
import { resolveTemplate, TypedLink } from '../../../../../hal';
import { Changeset } from '../../../../../repository/models/Changeset';
import { useGetChangesetQuery } from '../../../api';
import InstancePageDeployDialog from './components/InstancePageDeployDialog';
import { useVisitedTourLocation } from '../../../../tour/hooks';
import { Overview, OverviewItem, OverviewRow } from '../../../../../ui/Overview';
import { OverviewLinksRow } from './components/OverviewLinksRow';
import { OverviewState } from './components/OverviewState';
import { LatestDeployment } from './components/LatestDeployment';
import useQuery from '../../../../useQuery';

interface InstanceOverviewProps {
    project: Project;
    application: Application;
    deploymentList: readonly Deployment[] | undefined;
    zone: Zone | undefined;
}

export const InstanceOverview = ({ project, application, deploymentList, zone }: InstanceOverviewProps) => {
    const {
        data: releases,
        isLoading: releasesIsLoading,
        error: releasesError,
    } = useGetReleasesForProjectQuery(project);
    const relativeTime = new RelativeTime();

    const [deployDialogOpen, setDeployDialogOpen] = useState<boolean>(false);
    const [deployDialogAutoOpened, setDeployDialogAutoOpened] = useState<boolean>(false);

    const deployments = useMemo(() => {
        const list = deploymentList?.slice() ?? [];
        return list.sort(
            (a: Deployment, b: Deployment) => new Date(b.created).getTime() - new Date(a.created).getTime(),
        );
    }, [deploymentList]);

    const successfulDeployment = deployments.find((d) => d.status.finished && !d.status.failed) ?? null;

    const lastUpdate = !!successfulDeployment ? relativeTime.from(new Date(successfulDeployment.created)) : '—';
    const lastChangesetLink = !!successfulDeployment ? successfulDeployment._links.changeset.href : null;

    const {
        data: lastChangeset,
        error: lastChangesetError,
        isLoading: lastChangesetIsLoading,
    } = useGetChangesetQuery(lastChangesetLink ?? skipToken);

    useVisitedTourLocation(applicationDeployLocation, () => !!successfulDeployment, [successfulDeployment]);

    const query = useQuery();

    const canDeploy = resolveTemplate(application, 'deploy') !== null;

    if (query.get('deploying') && canDeploy && !deployDialogAutoOpened) {
        setDeployDialogOpen(true);
        setDeployDialogAutoOpened(true);
    }

    return (
        <>
            <Overview
                title="Application information"
                button={
                    <HighlightedComponent location={applicationDeployLocation} disabled={!canDeploy}>
                        <Button
                            disabled={!canDeploy}
                            startIcon={<DirectionsBoatIcon />}
                            color="primary"
                            variant="contained"
                            disableElevation
                            onClick={() => setDeployDialogOpen(true)}
                        >
                            Deploy
                        </Button>
                    </HighlightedComponent>
                }
            >
                <OverviewRow>
                    <OverviewItem grow={1} name="Name">
                        {application?.name}
                    </OverviewItem>

                    <OverviewItem grow={1} name="Zone">
                        {zone ? <>{zone.name}</> : '&mdash;'}
                    </OverviewItem>
                </OverviewRow>

                <OverviewRow>
                    <OverviewItem grow={1} name="Current application status">
                        <OverviewState
                            application={application}
                            deployment={deployments[0] ?? null}
                            releases={releases ?? []}
                        />
                    </OverviewItem>

                    <OverviewItem grow={1} name="Latest deployment">
                        <LatestDeployment deployment={deployments[0] ?? null} />
                    </OverviewItem>
                </OverviewRow>

                <OverviewRow>
                    <OverviewItem grow={1} name="Created">
                        <CreatedDate app={application} time={relativeTime} />
                    </OverviewItem>

                    <OverviewItem grow={1} name="Last update">
                        {lastUpdate}
                    </OverviewItem>

                    <OverviewItem grow={1} name="Last version">
                        <InstancePageLastReleaseIndicator
                            isLoading={releasesIsLoading || lastChangesetIsLoading}
                            error={releasesError ?? lastChangesetError}
                            releases={releases ?? []}
                            changeset={lastChangeset ?? null}
                            project={project}
                            isClicable
                        />
                    </OverviewItem>

                    <OverviewItem grow={1} name="Last changeset">
                        <LastChangesetDisplay lastChangeset={lastChangesetLink} />
                    </OverviewItem>
                </OverviewRow>

                <OverviewLinksRow isDeployed={successfulDeployment !== null} application={application} />
            </Overview>

            <InstancePageDeployDialog
                application={application}
                open={deployDialogOpen && canDeploy}
                isLoading={releasesIsLoading}
                error={releasesError}
                releases={releases ?? []}
                currentChangeset={lastChangeset ?? null}
                onClose={() => setDeployDialogOpen(false)}
            />
        </>
    );
};

interface CreateDateProps {
    app: Application;
    time: RelativeTime;
}

const CreatedDate = ({ app, time }: CreateDateProps) => {
    const d = new Date(app?.createdAt);
    return (
        <div>
            <Tooltip arrow title={d.toLocaleString()}>
                <span>{time.from(d)}</span>
            </Tooltip>
        </div>
    );
};

interface LastChangesetDisplayProps {
    lastChangeset: TypedLink<Changeset> | null;
}

const LastChangesetDisplay = ({ lastChangeset }: LastChangesetDisplayProps) => {
    if (lastChangeset === null) {
        return <>—</>;
    }
    const start = lastChangeset.toString().indexOf('changeset') + 10;
    return <>{lastChangeset.substring(start, start + 8)}</>;
};
