import { Box, Button, FormControl, FormControlLabel, Typography, Checkbox as UICheckbox, styled } from '@mui/material';
import React, { useMemo, useReducer } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';

import { IamRealm } from '../../repository/models/IamRealm';
import { RForm, resolveTemplate } from '../../hal';
import { useCreateRealmAttributeMutation, useGetRealmAttributesQuery } from './api';
import { inheritHalProperty, withHalOptions, withHalProperty } from '../../hal/forms/react';
import ValidatedTextInput from '../../hal/forms/ValidatedTextInput';
import { useNavigate } from 'react-router-dom';
import { IAM_REALM_ATTRIBUTES } from '../routes';
import { Organization } from '../../repository/models/Organization';
import BusyButton from '../../BusyButton';
import { PageTitle } from '../../ui/typography/PageTitle';
import { StyledSelect } from '../../ui/styles/StyledSelect';
import { RequestStateHandler } from '../../ui/RequestStateHandler';
import { SelectChangeEvent } from '@mui/material';


interface IamRealmCreateAttributeProps {
    realm?: IamRealm;
    org: Organization | null;
}

const defaultState = {
    name: '',
    type: 'STRING',
    multivalue: false,
};

export const IamRealmCreateAttribute = ({ realm, org }: IamRealmCreateAttributeProps) => {
    const {
        data: attributeList,
        isLoading: isRealmLoading,
        error: realmError,
    } = useGetRealmAttributesQuery(realm ?? skipToken);
    const [createAttribute, { error, isLoading }] = useCreateRealmAttributeMutation();
    const navigate = useNavigate();

    const attributeTemplate = useMemo(() => {
        if (!!attributeList) {
            return resolveTemplate(attributeList!, 'default');
        }
        return null;
    }, [attributeList]);

    const [formState, setFormState] = useReducer((state: typeof defaultState, action: Partial<typeof defaultState>) => {
        return { ...state, ...action };
    }, defaultState);

    const handleSubmit = async () => {
        try {
            await createAttribute({
                templateAttribute: attributeList!,
                attribute: formState!,
            }).unwrap();
            navigate(
                IAM_REALM_ATTRIBUTES.generate({
                    org: org?.name!,
                    realm: realm?.id!,
                }),
            );
        } catch (e) {
            // The error is shown by the variable from RTK Query
        }
    };

    return (
        <>
            <PageTitle>Create attribute</PageTitle>
            {!!error || !!realmError || isRealmLoading ? (
                <RequestStateHandler isLoading={isRealmLoading} error={error || realmError} />
            ) : null}
            <Box marginTop={2}>
                {attributeTemplate !== null && (
                    <RForm template={attributeTemplate}>
                        <StyledWrapper>
                            <Typography variant="body2" noWrap component="label" htmlFor="name">
                                Name
                            </Typography>
                            <HalValidatedTextInput
                                displayName="Name"
                                name="name"
                                id="name"
                                fullWidth
                                autoFocus
                                value={formState.name}
                                handleOnChange={(val) => setFormState({ name: val })}
                            />
                        </StyledWrapper>
                        <StyledWrapper>
                            <Typography variant="body2" noWrap component="label" htmlFor="last_name">
                                Type
                            </Typography>
                            <FormControl variant="filled" size="small">
                                <SelectAttrType
                                    native
                                    name="type"
                                    variant="outlined"
                                    value={formState.type}
                                    onChange={(e: SelectChangeEvent<unknown>) =>
                                        setFormState({
                                            type: e.target.value as string,
                                        })
                                    }
                                />
                            </FormControl>
                        </StyledWrapper>
                        <StyledWrapper>
                            <Box marginLeft={16}></Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="multivalue"
                                        checked={formState.multivalue}
                                        onChange={(e) =>
                                            setFormState({
                                                multivalue: e.target.checked,
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography component="span" variant="body2" noWrap>
                                        Multivalue
                                    </Typography>
                                }
                            />
                        </StyledWrapper>
                        <Box display="flex" justifyContent="flex-end">
                            <Box marginRight={2}>
                                <Button
                                    color="inherit"
                                    onClick={() =>
                                        navigate(
                                            IAM_REALM_ATTRIBUTES.generate({
                                                org: org?.name!,
                                                realm: realm?.id!,
                                            }),
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <BusyButton
                                busy={isLoading}
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Create attribute
                            </BusyButton>
                        </Box>
                    </RForm>
                )}
            </Box>
        </>
    );
};

const HalValidatedTextInput = inheritHalProperty(ValidatedTextInput);
const Checkbox = withHalProperty(UICheckbox);
const typeNames: Record<string, string> = {
    STRING: 'Text',
    NUMBER: 'Number',
    BOOLEAN: 'Boolean',
};

const SelectAttrType = withHalOptions(withHalProperty(StyledSelect), ({ value, prompt }) => (
    <option value={value}>{typeNames[value] ?? prompt}</option>
));

const StyledWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& .MuiTypography-root': {
        width: theme.spacing(16),
        minWidth: theme.spacing(16),
    },
}));
