import { Box } from '@mui/material';
import React from 'react';

import { Cell, CellProps } from '../table/Cell';

export const TableHeader = (props: CellProps) => {
    return (
        <Cell {...props} isLarge>
            <Box sx={{ fontWeight: 500 }}>{props.children}</Box>
        </Cell>
    );
};
