import React from 'react';
import { Box, List, Typography } from '@mui/material';
import { Alert } from '@mui/material';

import { Deployment } from '../../../../../repository/models/Deployment';
import { DeploymentsList } from './DeploymentList';

interface InstanceHistoryProps {
    deployments: Deployment[];
}

export const InstanceHistory = ({ deployments }: InstanceHistoryProps) => {
    if (deployments?.length === 0 || deployments === null) {
        return (
            <Alert severity="info">
                <Typography>There are no deployments yet.</Typography>
            </Alert>
        );
    }
    return (
        <Box padding={2} borderRadius={8} borderColor="divider" border={1}>
            <List disablePadding>
                <DeploymentsList deployments={deployments} />
            </List>
        </Box>
    );
};
