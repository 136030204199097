import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactNode } from 'react';

interface CardTitleProps {
    children: ReactNode;
}

export const CardTitle = ({ children }: CardTitleProps) => {
    const { classes } = useStyles();
    return (
        <Typography className={classes.title} variant="body1" component="h6">
            <Box sx={{ fontWeight: 600 }}>{children}</Box>
        </Typography>
    );
};

const useStyles = makeStyles()(() =>
    ({
        title: {
            marginBottom: '1rem',
        },
    }),
);
