import { Tab, TabProps } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import React, { ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react';
import { LeafRoute, Route } from '../../features/routes';

interface LinkTabSpecificProps<Variables extends string> {
    readonly from: Route<any, Variables>;
    readonly to: LeafRoute<any, Variables>;
    readonly activeRoutes?: readonly Route[];
}

type LinkTabProps<Variables extends string> = Omit<
    TabProps<typeof Link>,
    keyof LinkTabSpecificProps<Variables> | 'value'
> &
    LinkTabSpecificProps<Variables>;

const LinkTab = forwardRef(function LinkTab<Variables extends string>(
    { from, to, activeRoutes, ...props }: LinkTabProps<Variables>,
    ref: ForwardedRef<HTMLAnchorElement>,
) {
    const location = useLocation();
    const navigationTarget = from.navigate(location.pathname, to);

    return <Tab ref={ref} component={Link} to={navigationTarget!} disabled={navigationTarget === null} {...props} />;
});

export default LinkTab;

export function isLinkTab(element: ReactNode): element is ReactElement<LinkTabProps<any>, typeof LinkTab> {
    return React.isValidElement(element) && element.type === LinkTab;
}
