import React from 'react';
import { createSvgIcon } from '@mui/material';

export const RequireIcon = createSvgIcon(
    <path
        d="m22.036 16.656-8.0656-4.6568 8.0656-4.656c0.29666-0.17311 0.4918-0.49023 0.4918-0.8522 0-0.54374-0.44066-0.98361-0.98361-0.98361-0.18098 0-0.35095 0.048787-0.49652 0.13456l0.0047-0.00236-8.0656 4.6568v-9.3128c2.2e-5 -0.54295-0.44063-0.98361-0.98358-0.98361s-0.98361 0.44066-0.98361 0.98361v9.3128l-8.0648-4.6568c-0.14085-0.082623-0.31082-0.13141-0.4918-0.13141-0.54374 0-0.98361 0.44066-0.98361 0.98361 0 0.36197 0.19593 0.67908 0.48708 0.84984l0.00472 0.00236 8.0648 4.656-8.0648 4.6568c-0.2998 0.17233-0.4981 0.49023-0.4981 0.85534 0 0.54295 0.43987 0.98361 0.98361 0.98361 0.18413 0 0.35567-0.05036 0.50282-0.13849l-0.00472 0.0024 8.0648-4.656v9.3128c0 0.54295 0.44066 0.98361 0.98361 0.98361s0.98361-0.44066 0.98361-0.98361v-9.3128l8.0656 4.656c0.13928 0.08026 0.30688 0.12826 0.48472 0.12826 0.54295 0 0.98361-0.43987 0.98361-0.98361 0-0.35882-0.19279-0.67357-0.48-0.84511l-0.0047-0.0024z"
        strokeWidth=".78688"
    />,
    'Require',
);
