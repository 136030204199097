import {
    Typography,
    Breadcrumbs as MuiBreadcrumbs,
    BreadcrumbsProps as MuiBreadcrumbsProps,
    TypographyProps,
    Box,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { Children } from 'react';
import { ReactNode } from 'react';
import { theme } from '../theme';

function NavTypography(props: TypographyProps<'nav'>) {
    return <Typography component="nav" variant="h6" {...props} />;
}

type BreadcrumbsProps = {
    children: ReactNode;
    subtitle?: ReactNode;
} & Omit<MuiBreadcrumbsProps<typeof NavTypography>, 'component' | 'separator'>;

export default function Breadcrumbs({ children, ...props }: BreadcrumbsProps) {
    const c = Children.toArray(children);

    const sub =
        typeof props.subtitle === 'string' ? (
            <Typography variant="subtitle1">{props.subtitle}</Typography>
        ) : (
            props.subtitle
        );

    return (
        <Box mb={4}>
            <MuiBreadcrumbs
                component={NavTypography}
                separator={<NavigateNextIcon />}
                {...props}
                sx={{
                    '&.MuiBreadcrumbs-root': {
                        typography: theme.typography.h6,
                    },
                    '& .MuiLink-root': {
                        textDecoration: 'none',
                        color: 'rgba(0, 0, 0, 0.54)',
                        '&:hover': {
                            textDecoration: 'underline',
                            color: 'rgba(0, 0, 0, 0.54)',
                        },
                    },
                }}
            >
                {c.slice(0, c.length - 1)}
                <Typography color="textPrimary" variant="inherit">
                    {c[c.length - 1]}
                </Typography>
            </MuiBreadcrumbs>
            {sub ?? null}
        </Box>
    );
}
