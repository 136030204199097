import React from 'react';
import { Box, IconButton, Theme } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { measurements } from '../../theme';

type LeftBackIconButtonProps = {
    onClick: () => void;
    variant: 'h5' | 'h6';
};

const fullWidth = 40 + 10; // width + gap
const content = measurements.content.width;
const sidebar = measurements.sidebar.width;

const useStyles = makeStyles()((_theme: Theme) => ({
    root: {
        width: 0,
        transform: `translateX(-${fullWidth}px)`,
        [_theme.breakpoints.down(content + sidebar + fullWidth)]: {
            width: `${fullWidth}px`,
            transform: 'none',
        },
    },
    h5: {
        top: '-1px',
    },
    h6: {
        top: '-2px',
    },
    iconOutline: {
        width: '40px',
        height: '40px',
    },
}));

export default function LeftBackIconButton({ onClick, variant }: LeftBackIconButtonProps) {
    const { classes } = useStyles();
    return (
        <Box position="relative">
            <Box className={classNames(classes['root'], classes[variant])}>
                <IconButton className={classes['iconOutline']} color="secondary" onClick={onClick}>
                    <ArrowBack />
                </IconButton>
            </Box>
        </Box>
    );
}
