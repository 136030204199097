import { Button, ButtonGroup, Typography, TypographyProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid2';
import React, { ComponentType, ReactNode, useState } from 'react';
import BusyButton from '../../BusyButton';

type PartiallyEditableTitleInputProps = {
    readonly variant: TypographyProps['variant'];
    readonly prefix?: ReactNode;
    readonly suffix?: ReactNode;
    readonly value: string;
} & (DisabledPartiallyEditableTitleInputProps | EnabledPartiallyEditableTitleInputProps);

type DisabledPartiallyEditableTitleInputProps = {
    readonly onEditRequest?: undefined | null;
} & Partial<EnabledPartiallyEditableTitleInputProps>;

interface EnabledPartiallyEditableTitleInputProps {
    readonly editing: boolean;
    readonly editActionButtonText: string;

    readonly onEditRequest: () => void;
    readonly onEditCancel: () => void;
    readonly onEditSave: (value: string) => Promise<void> | void;
    readonly editIsSaving: boolean;

    readonly TextInput: ComponentType<TextInputProps>;
}

interface TextInputProps {
    readonly value: string;
    readonly className?: string;
    readonly onChange: (value: string) => void;
    readonly onEnterPressed?: () => void;
}

export default function PartiallyEditableTitleInput(props: PartiallyEditableTitleInputProps) {
    const { classes } = useStyles();
    const [newValue, setNewValue] = useState<string | null>(null);

    return (
        <Grid container justifyContent="space-between" direction="row" className={classes.wrapper}>
            <Grid>
                <Typography variant={props.variant} className={classes.title}>
                    {props.prefix ? <>{props.prefix}&nbsp;</> : null}
                    <EditableTitleInput
                        variant={props.variant}
                        editing={props.editing}
                        value={newValue ?? props.value}
                        onChange={setNewValue}
                        onEnterPressed={async () => {
                            await props.onEditSave!(newValue ?? props.value);
                            setNewValue(null);
                        }}
                        TextInput={props.TextInput}
                    />
                    {props.suffix ? <>&nbsp;{props.suffix}</> : null}
                </Typography>
            </Grid>
            {props.editing ? (
                <Grid>
                    <ButtonGroup variant="contained" disableElevation>
                        <Button
                            color="inherit"
                            className={classes.button}
                            key="cancel"
                            disabled={props.editIsSaving}
                            onClick={() => {
                                setNewValue(null);
                                props.onEditCancel!();
                            }}
                        >
                            Cancel
                        </Button>
                        <BusyButton
                            key="save"
                            color="primary"
                            disabled={newValue === null || newValue === props.value}
                            onClick={async () => {
                                await props.onEditSave!(newValue ?? props.value);
                                setNewValue(null);
                            }}
                            busy={props.editIsSaving ?? false}
                        >
                            Save
                        </BusyButton>
                    </ButtonGroup>
                </Grid>
            ) : props.onEditRequest ? (
                <Grid>
                    <Button key="edit-request" variant="outlined" color="primary" onClick={props.onEditRequest}>
                        {props.editActionButtonText}
                    </Button>
                </Grid>
            ) : null}
        </Grid>
    );
}

interface EditableTitleInputProps {
    readonly variant: PartiallyEditableTitleInputProps['variant'];
    readonly value: string;
    readonly editing?: boolean;
    readonly onChange?: (value: string) => void;
    readonly onEnterPressed?: () => void;
    readonly TextInput?: PartiallyEditableTitleInputProps['TextInput'];
}

function EditableTitleInput(props: EditableTitleInputProps) {
    const { classes } = useStyles();
    if (!props.editing) {
        return <i>{props.value}</i>;
    }

    const TextInput = props.TextInput!;

    return (
        <TextInput
            className={classes.input}
            value={props.value}
            onChange={props.onChange!}
            onEnterPressed={props.onEnterPressed}
        />
    );
}

const useStyles = makeStyles()(() => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
    },
    input: {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        fontStyle: 'italic',
    },
    wrapper: {
        width: '100%',
    },
    button: {
        '&.MuiButton-root': {
            borderRight: '1px solid #bdbdbd',
        },
    },
}));
