import React from 'react';
import { styled } from '@mui/material';
import { Check, Warning } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';

import { theme } from '../../../../../../theme';
import { Application } from '../../../../../../repository/models/Application';
import ApplicationState from '../../../../components/ApplicationState';
import { Deployment } from '../../../../../../repository/models/Deployment';
import { Release } from '../../../../../../repository/models/Release';
import { useGetChangesetQuery, useGetApplicationHealthInfoQuery } from '../../../../api';
import { ServerErrorMessage } from '../../../../../../ui/ServerErrorMessage';
import useInterval from '../../../../../interval';

const polling = 3000;
interface OverviewStateProps {
    deployment: Deployment | null;
    application: Application;
    releases: readonly Release[];
}

export const OverviewState = ({ application, deployment, releases }: OverviewStateProps) => {
    const { data: changeset, isLoading, error } = useGetChangesetQuery(deployment?._links.changeset.href ?? skipToken);
    const { data: health, refetch } = useGetApplicationHealthInfoQuery(application);

    useInterval(() => {
        if (deployment?.status.finished && health?.progressing) {
            refetch();
        }
    }, polling);

    if (isLoading) {
        return <CircularProgress disableShrink size="1em" />;
    }

    if (!!error) {
        return <ServerErrorMessage textView error={error} />;
    }

    if (health?.available) {
        return (
            <StyledWrapper>
                <>
                    <Check fontSize="small" htmlColor={theme.palette.success.main} />
                    <span>Ready</span>
                </>
            </StyledWrapper>
        );
    }

    if (health?.errored) {
        return (
            <StyledWrapper>
                <>
                    <Warning fontSize="small" htmlColor={theme.palette.warning.main} />
                    <span>Error</span>
                </>
            </StyledWrapper>
        );
    }

    if (health?.progressing) {
        return (
            <StyledWrapper>
                <>
                    <CircularProgress disableShrink size="1em" />
                    <span>Starting</span>
                </>
            </StyledWrapper>
        );
    }

    if (application.state.available && deployment && deployment.status.failed === false) {
        if (deployment.status.finished) {
            return (
                <StyledWrapper>
                    <Check fontSize="small" htmlColor={theme.palette.success.main} />
                    <span>Deployed</span>
                </StyledWrapper>
            );
        }

        const labelForDeployment = !!changeset
            ? releases.filter((r) => r._links.changeset.href === changeset._links.self.href)[0]?.label
            : undefined;
        return (
            <StyledWrapper>
                <CircularProgress disableShrink size="1em" />
                <span>Deploying {labelForDeployment}</span>
            </StyledWrapper>
        );
    }

    return (
        <StyledWrapper>
            <ApplicationState state={application.state} />
        </StyledWrapper>
    );
};

const StyledWrapper = styled('div')(() => ({
    display: 'inline-flex',
    alignItems: 'center',
    gap: '.25em',
}));
