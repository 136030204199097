import React from 'react';
import {
    Button,
    FormControlLabel,
    IconButton,
    Typography,
    Link as MuiLink,
    Box,
    Checkbox,
    Paper,
    Theme,
} from '@mui/material';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from 'tss-react/mui';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Alert } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';

import { Blueprint } from '../../repository/models/Blueprint';
import { ExpressionDto, PolicyCreationDto, PolicyDto, Verb } from '../../repository/models/PolicyDto';
import { DataModelContext } from './DataModelContext';
import BusyButton from '../../BusyButton';
import { Entity } from '../../repository/models/Entity';
import { useGetEntityListQuery } from '../datamodel/api';
import { PolicyVerbs } from './PolicyVerbs';
import PolicyDeletionButton from './PolicyDeletionButton';
import { HighlightedComponent, permissionAddPolicyLocation } from '../tour';
import FormActions from '../../ui/FormActions';
import { permissionsUrl } from '../../documentation';
import PolicyConditionEditTrigger from './PolicyConditionEditTrigger';
import permissionsApi from './api';
import { ServerErrorMessage } from '../../ui/ServerErrorMessage';
import { SerializedError } from '@reduxjs/toolkit';
import { RequestStateHandler } from '../../ui/RequestStateHandler';

type PolicyModelFormProps = {
    currentBlueprint: Blueprint;
    entityName: string;
    policy: PolicyDto | null;
    isSaving: boolean;
    error: SerializedError | undefined;
    onSave: (data: PolicyCreationDto) => void;
    onCancel: () => void;
    onDelete?: () => void;
};

export function PolicyModelForm(props: PolicyModelFormProps) {
    const { data, isLoading, error } = useGetEntityListQuery(props.currentBlueprint ?? skipToken);
    const [getSuggestionTrigger, { isLoading: suggestionLoading, error: suggestionError }] =
        permissionsApi.endpoints.getPolicyConditionSuggestions.useLazyQuery();

    const dataModel: { [name: string]: Entity } | undefined = data?._embedded?.entities
        ? data?._embedded?.entities.reduce((acc, e) => ({ ...acc, [e.name]: e }), {})
        : undefined;
    const initialState = props.policy ?? {
        verbs: ['read', 'create', 'update', 'delete'],
        requires_authentication: true,
        conditions: [],
    };

    const [verbsEnabled, setVerbsEnabled] = React.useState<Verb[]>(initialState.verbs);

    const [visibilityValue, setVisibilityValue] = React.useState<boolean>(initialState.requires_authentication);

    // Why this additional type? See https://reactjs.org/docs/lists-and-keys.html#keys
    // > We don’t recommend using indexes for keys if the order of items may change.
    // > This can negatively impact performance and may cause issues with component state.
    // The order of the policies within the list may be changed, but we use the original
    // insertion/creation order as the id field, to be used as `key` property when rendering.
    type ConditionEntry = { id: number; expr: ExpressionDto; isNew: boolean };

    const [conditions, setConditions] = React.useState<ConditionEntry[]>(
        initialState.conditions.map((expr, id) => ({ id, expr, isNew: false })),
    );

    const addDefaultCondition = async () => {
        const suggestion = await getSuggestionTrigger(
            { blueprint: props.currentBlueprint, entity: props.entityName },
            true,
        ).unwrap();
        const condition =
            suggestion?.condition ??
            ({
                // If suggestion.condition is undefined, there's probably just no attributes on the whole entity.
                left: { type: 'user', value: ['foo'] },
                oper: 'equals',
                right: { type: 'constant:string', value: [''] },
            } as ExpressionDto);
        // Make id 1 higher than current highest id, or make id 0 if there aren't any others
        let id = Math.max(-1, ...conditions.map((cond) => cond.id)) + 1;
        setConditions((existing) => [...existing, { id: id, expr: condition, isNew: true }]);
    };

    const moveConditionUp = (index: number) => {
        if (index === 0) {
            return;
        }
        setConditions((existing) =>
            existing
                .slice(0, index - 1)
                .concat(existing.slice(index - 1, index + 1).reverse())
                .concat(existing.slice(index + 1)),
        );
    };
    const moveConditionDown = (index: number) => {
        if (index === conditions.length - 1) {
            return;
        }
        setConditions((existing) =>
            existing
                .slice(0, index)
                .concat(existing.slice(index, index + 2).reverse())
                .concat(existing.slice(index + 2)),
        );
    };
    const deleteCondition = (index: number) => {
        setConditions((existing) => existing.slice(0, index).concat(existing.slice(index + 1)));
    };

    const savePolicy = () => {
        let policy: PolicyCreationDto = {
            entity: props.entityName,
            requires_authentication: visibilityValue,
            create: verbsEnabled.includes('create'),
            read: verbsEnabled.includes('read'),
            update: verbsEnabled.includes('update'),
            delete: verbsEnabled.includes('delete'),
            conditions: conditions.map((entry) => entry.expr),
        };
        props.onSave(policy);
    };

    const { classes } = useStyles();

    if (isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />;
    }

    return (
        <DataModelContext.Provider value={dataModel}>
            <Alert severity="info">
                <MuiLink underline="always" color="primary" href={permissionsUrl} target="_blank" rel="noreferrer">
                    Read the docs for more detailed instructions.
                </MuiLink>
            </Alert>

            <Typography className={classes.sectionHeader}>Operations allowed by this policy</Typography>
            <PolicyVerbs verbs={verbsEnabled} onChange={setVerbsEnabled} />

            <Typography className={classes.sectionHeader}>Conditions</Typography>
            {conditions.length === 0 ? (
                <p>Operation is allowed (no additional conditions).</p>
            ) : (
                <p>Operation is allowed when...</p>
            )}

            <Paper variant="outlined">
                <Box marginX={2} marginBottom={2}>
                    <FormControlLabel
                        disabled
                        control={
                            <Checkbox
                                name="visibility"
                                checked={visibilityValue}
                                color="primary"
                                disabled
                                onChange={(e) => setVisibilityValue(e.target.checked)}
                            />
                        }
                        label={
                            <Typography component="span" noWrap>
                                Request requires authentication
                            </Typography>
                        }
                    />
                    <div>
                        {conditions.map(({ expr: cond, id, isNew }, i) => (
                            <div className={classes.conditionRow} key={id}>
                                <Box display="flex" height={40} alignItems="center">
                                    <Typography variant="body2">...and&nbsp; </Typography>
                                    <PolicyConditionEditTrigger
                                        blueprint={props.currentBlueprint}
                                        entityName={props.entityName}
                                        condition={cond}
                                        onChange={(newCondition: ExpressionDto) => {
                                            setConditions(
                                                conditions.map((c, j) =>
                                                    i === j
                                                        ? {
                                                            expr: newCondition,
                                                            id,
                                                            isNew: false,
                                                        }
                                                        : c,
                                                ),
                                            );
                                        }}
                                        isNew={isNew}
                                    />
                                </Box>
                                <Box component="span" marginLeft="auto" whiteSpace="nowrap">
                                    <IconButton size="small" onClick={() => moveConditionDown(i)}>
                                        <ArrowDownIcon />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => moveConditionUp(i)}>
                                        <ArrowUpIcon />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => deleteCondition(i)}>
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Box>
                            </div>
                        ))}
                    </div>

                    <ServerErrorMessage error={suggestionError} />
                    <BusyButton
                        busy={suggestionLoading}
                        color="primary"
                        variant="outlined"
                        onClick={() => addDefaultCondition()}
                    >
                        Add Condition
                    </BusyButton>
                </Box>
            </Paper>
            {props.policy === null ? null : (
                <>
                    <Typography className={classes.sectionHeader}>Delete this policy</Typography>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '1rem',
                            justifyContent: 'space-between',
                        }}
                    >
                        You can delete the current policy. This action cannot be undone.
                        <PolicyDeletionButton policy={props.policy} onDelete={props.onDelete ?? (() => { })} />
                    </div>
                </>
            )}

            <ServerErrorMessage error={props.error} />

            <FormActions className={classes.formActions}>
                <Box marginTop={1} display="flex" width="100%" justifyContent="flex-end">
                    <Box marginRight={1}>
                        <Button color="secondary" disabled={props.isSaving} onClick={props.onCancel}>
                            Cancel
                        </Button>
                    </Box>

                    <HighlightedComponent location={permissionAddPolicyLocation} disabled={props.isSaving}>
                        <BusyButton
                            busy={props.isSaving}
                            variant="contained"
                            disableElevation
                            color="primary"
                            onClick={savePolicy}
                        >
                            Save
                        </BusyButton>
                    </HighlightedComponent>
                </Box>
            </FormActions>
        </DataModelContext.Provider>
    );
}

const useStyles = makeStyles()((theme: Theme) => ({
    conditionRow: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        '&:first-of-type > .MuiBox-root > p': {
            visibility: 'hidden',
        },
    },
    sectionHeader: {
        marginTop: theme.spacing(4),
        fontWeight: 'bold',
    },
    formActions: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1, 0),
        justifyContent: 'flex-start',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white',
        borderTop: 'solid 1px ' + theme.palette.divider,
    },
}));
