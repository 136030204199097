import RelativeTime from '@yaireo/relative-time';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';

import { OrganizationInvite } from '../../../../repository/models/Invite';
import { SerializedError } from '@reduxjs/toolkit';
import BusyButton from '../../../../BusyButton';
import { ServerErrorMessage } from '../../../../ui/ServerErrorMessage';

const relativeTime = new RelativeTime();

interface InvitesTableProps {
    invites: OrganizationInvite[];
    loading: boolean;
    error: SerializedError | undefined;
    onCancel: (invite: OrganizationInvite) => void;
}

export const InvitesTable = ({ invites, loading, error, onCancel }: InvitesTableProps) => {
    const [opened, setOpened] = useState(false);
    const [selectedInvite, setSelectedInvite] = useState<OrganizationInvite | null>(null);

    const updatedInvites = invites?.map((m) => ({
        ...m,
        invitedAt: new Date(m.invited_at),
    }));

    const handleIconClick = (invite: OrganizationInvite) => {
        setOpened(true);
        setSelectedInvite(invite);
    };

    const handleConfirm = () => {
        onCancel(selectedInvite!);
        setOpened(false);
    };

    if (!invites?.length) {
        return <Typography>No open invitations!</Typography>;
    }

    if (!!invites?.length) {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label="table of users">
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Invited by</TableCell>
                                <TableCell>Since</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {updatedInvites?.map((invite) => {
                                const date = invite.invitedAt.toLocaleString();
                                const since = relativeTime.from(invite.invitedAt);

                                return (
                                    <TableRow key={date}>
                                        <TableCell>{invite.user_email}</TableCell>
                                        <TableCell>{invite.invited_by_display_name}</TableCell>
                                        <TableCell>
                                            <Tooltip title={date}>
                                                <span>{since}</span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell padding="none">
                                            <Tooltip title="Cancel invitation">
                                                <StyledButton onClick={() => handleIconClick(invite)}>
                                                    <CloseIcon fontSize="small" />
                                                </StyledButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Dialog open={opened} onClose={() => setOpened(false)}>
                    <DialogTitle>
                        <Box sx={{ textAlign: 'center' }}>Cancel invitation</Box>
                    </DialogTitle>

                    <DialogContent>
                        <Typography variant="body1">
                            Are you sure you want to cancel invitation for {selectedInvite?.user_email}?
                        </Typography>

                        <ServerErrorMessage error={error} />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => setOpened(false)}>Dismiss</Button>
                        <BusyButton busy={loading} variant="contained" color="primary" onClick={handleConfirm}>
                            Confirm
                        </BusyButton>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return null;
};

const StyledButton = styled(IconButton)({
    opacity: 0.6,
    '&:hover': {
        opacity: 1,
    },
});
