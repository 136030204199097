import { CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';
import MuiLink from '@mui/material/Link';

import { Changeset } from '../../../../../../repository/models/Changeset';
import { Release } from '../../../../../../repository/models/Release';
import { RELEASE_SHOW } from '../../../../../routes';
import { Project } from '../../../../../../repository/models/Project';

type InstancePageLastReleaseIndicatorProps = {
    isLoading: boolean;
    error: SerializedError | undefined;
    releases: readonly Release[];
    changeset: Changeset | null;
    isClicable?: boolean;
    project?: Project;
};

export default function InstancePageLastReleaseIndicator({
    releases,
    changeset,
    isLoading,
    error,
    isClicable,
    project,
}: InstancePageLastReleaseIndicatorProps) {
    if (isLoading) {
        return <CircularProgress size={12} />;
    }
    if (!!error) {
        return (
            <Typography component="span" variant="body2" color="error">
                Data has not been loaded!
            </Typography>
        );
    }
    const lastRelease = releases?.find((release) => release._links.changeset.href === changeset?._links.self.href);
    if (!!lastRelease && !!project && isClicable) {
        const releaseLink = RELEASE_SHOW.generate({
            org: project!.organization,
            project: project!.name,
            release: lastRelease!.label,
        });
        return (
            <Typography variant="body2">
                <MuiLink underline="hover" component={Link} to={releaseLink}>
                    {lastRelease.label}
                </MuiLink>
            </Typography>
        );
    }

    if (lastRelease) {
        return <>{lastRelease.label}</>;
    }
    return <>&mdash;</>;
}
