import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ReportProblemOutlined, CheckCircleOutline, InfoOutlined } from '@mui/icons-material';
import React from 'react';

type ApiVersionProps = Record<'base' | 'current', Readonly<Record<'major' | 'minor', number>>>;

const useApiVersionStyles = makeStyles()((theme: Theme) =>
    ({
        root: {
            display: 'inline-flex',
            alignItems: 'center',
        },
        majorChange: {
            color: theme.palette.warning.dark,
        },
        minorChange: {
            color: theme.palette.success.dark,
        },
        noChange: {
            color: theme.palette.info.dark,
        },
    }),
);

export function ApiVersion({ base, current }: ApiVersionProps) {
    const {classes, cx} = useApiVersionStyles();
    const isInitialChange = base.major === 0;
    const isMajorChange = !isInitialChange && (base.major !== current.major || base.minor > current.minor);
    const isMinorChange = !isInitialChange && !isMajorChange && base.minor !== current.minor;

    const apiClasses =  cx(classes.root, {
        [classes.majorChange]: isMajorChange,
        [classes.minorChange]: isMinorChange,
        [classes.noChange]: !isInitialChange && !isMajorChange && !isMinorChange,
    });

    return (
        <span className={apiClasses}>
            {current.major}.{current.minor}&emsp;
            {isMajorChange ? (
                <>
                    <ReportProblemOutlined fontSize="inherit" />
                    &nbsp;Changes break backward compatibility
                </>
            ) : null}
            {isMinorChange ? (
                <>
                    <CheckCircleOutline fontSize="inherit" />
                    &nbsp;Backwards compatible changes
                </>
            ) : null}
            {!isInitialChange && !isMajorChange && !isMinorChange ? (
                <>
                    <InfoOutlined fontSize="inherit" />
                    &nbsp;No API changes
                </>
            ) : null}
        </span>
    );
}
