import { Tooltip } from '@mui/material';
import RelativeTime from '@yaireo/relative-time';
import React from 'react';

import { Cell } from '../../../ui/table/Cell';
import { Release } from '../../../repository/models/Release';
import { Project } from '../../../repository/models/Project';
import { TableRowHeader } from '../../../ui/typography/TableRowHeader';
import { RowLink } from '../../../ui/table/RowLink';
import { RELEASE_SHOW } from '../../routes';

type ReleasesRowProps = {
    project: Project | null;
    isNotFirstDescendant?: boolean;
    release: Release;
};

const relativeTime = new RelativeTime();

export const ReleasesRow = ({ release, isNotFirstDescendant, project }: ReleasesRowProps) => {
    const releaseLink = RELEASE_SHOW.generate({
        org: project!.organization,
        project: project!.name,
        release: release.label,
    });

    const date = relativeTime.from(new Date(release.created));
    const tooltipDate = release.created.toLocaleString();

    return (
        <RowLink borders="horizontal" isNotFirstDescendant={isNotFirstDescendant} link={releaseLink}>
            <TableRowHeader width={10}>{release.label}</TableRowHeader>

            <Cell>{release.message}</Cell>

            <Cell>{release.author ? <>{release.author}</> : 'Unknown'}</Cell>

            <Cell width={20}>
                <Tooltip title={tooltipDate}>
                    <>{date}</>
                </Tooltip>
            </Cell>
        </RowLink>
    );
};
