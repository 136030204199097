import { createTheme } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        danger: Palette['primary'];
        success: Palette['primary'];
        warning: Palette['warning'];
    }

    interface PaletteOptions {
        success?: PaletteOptions['primary'];
        danger?: PaletteOptions['primary'];
        warning?: PaletteOptions['warning'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        danger: true;
    }
}

export const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    boxSizing: 'border-box',
                    '--toastify-color-light': '#fff',
                    '--toastify-color-dark': '#121212',
                    '--toastify-color-info': '#e5f6fd',
                    '--toastify-color-success': '#edf7ed',
                    '--toastify-color-warning': '#fff4e5',
                    '--toastify-color-error': '#fdeded',
                    '--toastify-text-color-info': '#014361',
                    '--toastify-text-color-success': '#1e4620',
                    '--toastify-text-color-warning': '#663c00',
                    '--toastify-text-color-error': '#5f2120',
                },
                body: {
                    WebkitFontSmoothing: 'antialiased',
                    MozOsxFontSmoothing: 'grayscale',
                    margin: 0,
                    color: '#2e3538',
                    backgroundColor: '#fff',
                },
                code: {
                    fontFamily: 'source-code-pro, "Menlo", "Monaco", "Consolas", "Courier New", monospace',
                },
                '.MuiAlert-message': {
                    wordBreak: 'break-word',
                },
                '.Toastify__toast--info': {
                    background: '#3498db',
                },
                '.Toastify__toast--success': {
                    background: '#07bc0c',
                },
                '.Toastify__toast--warning': {
                    background: '#f1c40f',
                },
                '.Toastify__toast--error': {
                    background: '#e74c3c',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    padding: '6px',
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '& .MuiList-root .MuiListItem-root, & .MuiList-root .MuiListItemButton-root': {
                        paddingLeft: '32px',
                    }
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: '600px',
                    maxWidth: 'calc(100vw - 20rem)',
                    padding: '1rem',
                    '& .MuiDialogTitle-root': {
                        padding: `0 24px`,
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h5: {
                    fontWeight: 500,
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    marginBottom: '12px',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    '.MuiDialogTitle-root + &': {
                        // DialogContent.js has an override that removes top padding when there is a title. See https://github.com/mui/material-ui/issues/26795
                        // This is annoying for us (it makes several things clip behind the DialogTitle), so we add a fixed 8px top padding.
                        // If you want a different padding in your specific dialog, just add a div or Box in the DialogContent.
                        paddingTop: '8px',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.MuiTextField-root': {
                        '&:hover': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#009FE3',
                            },
                        },
                        '& .Mui-error:hover': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#f44336',
                            },
                        },
                    },
                },
            },
        },
    },
    palette: {
        primary: {
            light: '#e4f7ff',
            main: '#009FE3',
            dark: '#007aae',
            contrastText: '#fff',
        },
        secondary: {
            main: '#58595b',
            light: '#A7A9AC',
            dark: '#231F20',
            contrastText: '#fff',
        },
        success: {
            main: green[700],
        },
        danger: {
            main: red[700],
            light: red[500],
            dark: red[900],
            contrastText: '#fff',
        },
        warning: {
            main: orange[500],
        },
        text: {
            primary: '#2e3538',
        },
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: [
            '-apple-system',
            '"BlinkMacSystemFont"',
            '"Segoe UI"',
            '"Roboto"',
            '"Oxygen"',
            '"Ubuntu"',
            '"Cantarell"',
            '"Fira Sans"',
            '"Droid Sans"',
            '"Helvetica Neue"',
            'sans-serif',
        ].join(','),
    },
});

export const measurements = {
    sidebar: {
        width: 240,
    },
    content: {
        width: 960,
    },
};
